import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as reservationsActions from '../../store/actions/reservations';
import * as timesActions from '../../store/actions/times';
import { resources } from './resources';
import { useAuth0 } from '../../hooks/auth0';

// material ui components
import {
  TextField,
  Grid
} from '@material-ui/core';

import { blue } from '@material-ui/core/colors';
import { green } from '@material-ui/core/colors';
import AssignmentIndRoundedIcon from '@material-ui/icons/AssignmentIndRounded';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import {
  Scheduler,
  WeekView,
  Appointments,
  AppointmentTooltip,
  Resources
} from '@devexpress/dx-react-scheduler-material-ui';

import {
  CheckCircle as SuccessIcon,
  Error as DangerIcon
} from '@material-ui/icons';

// components
import Spinner from '../../components/UI/Spinner/Spinner';
import Snackbar from '../../components/UI/Snackbar/Snackbar';
import GridContainer from '../../components/UI/Grid/GridContainer';
import GridItem from '../../components/UI/Grid/GridItem';
import Card from '../../components/UI/Card/Card';
import CardHeader from '../../components/UI/Card/CardHeader';
import CardBody from '../../components/UI/Card/CardBody';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';

// styles
import classes from '../Reservations/Reservations.module.css';
import tableStyle from '../../assets/jss/components/tableStyle';
import { withStyles } from '@material-ui/core/styles';

const snackbarIcon = {
  success: SuccessIcon,
  danger: DangerIcon
};


const Times = (props) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'success'
  });

  const { getIdTokenClaims } = useAuth0();

  const dispatch = useDispatch();
  const { times, loadingTimes } = useSelector(state => state.times);
  const { centers } = useSelector(state => state.centers);

  const theme = createMuiTheme({ palette: { type: "light", primary: blue } });

  useEffect(() => {
    if (centers.length === 0) {
      fetchSportsCenters();
    }
    if (centers.length === 1) {
      fetchTimes(centers[0].entityId);
    }
  }, []);

  const fetchTimes = async (entityId) => {
    const { __raw } = await getIdTokenClaims();
    dispatch(timesActions.fetchTimes(__raw, entityId));
  };

  const fetchSportsCenters = async () => {
    const { __raw } = await getIdTokenClaims();
    dispatch(reservationsActions.fetchSportsCenters(__raw));
  };

  function sportCenterChange(event, value) {
    if (value) {
      fetchTimes(value.entityId);
    }else{
      fetchTimes(null);
    }
  };

  const Content = withStyles(tableStyle, { name: 'Content' })(({
    children, appointmentData, classes, ...restProps
  }) => (
    <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
      {appointmentData.maxQuotas != null ? (
       <div style={{ padding: 20 }}>
      <Grid container > 
      <Grid item xs={2}>
        <AssignmentIndRoundedIcon style={{ color: green[500]}}></AssignmentIndRoundedIcon>
      </Grid>
        <Grid item xs={10}>
         <span>Cupos utilizados {appointmentData.usedQuotas == null ? 0 :appointmentData.usedQuotas} de  {appointmentData.maxQuotas}</span>
        </Grid>
      </Grid>
      </div>
      ):null}
    </AppointmentTooltip.Content>
      
  ));

  let timesList = null;

  if (loadingTimes) {
    timesList = <Spinner />;
  } else {
    if (times.length > 0) {
      timesList = (
        <MuiThemeProvider theme={theme}>
          <div >
            <GridContainer >
              <GridItem xs={12}>
                <Card>
                  <CardHeader color="primary">
                    <h4 className={props.classes.cardTitleWhite}>Horarios</h4>
                  </CardHeader>
                  <CardBody>
                    <Scheduler data={times} locale='es' firstDayOfWeek={1} timeZone='America/Buenos_Aires'>
                      <WeekView startDayHour={5}
                        endDayHour={23}
                        cellDuration={60}
                      />
                      <Appointments />
                      <AppointmentTooltip
                        contentComponent={Content}
                        showCloseButton
                      />
                      <Resources
                        data={resources}
                        mainResourceName="groupId"
                      />
                    </Scheduler>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </MuiThemeProvider>
      );
    } else {
      //no hay reservas para el centro logueado.
      timesList = (
        <Alert variant="outlined" severity="info">
          <AlertTitle>Atención!</AlertTitle>
          No existen horarios registrados para tu centro deportivo.
          <span>Cualquier consulta comunicate con <strong>soporte@tupaseapp.com</strong></span>
        </Alert>
      );
    }
  }

  let autoCompleteList = null;

  if (centers.length > 1) {
    autoCompleteList = (
      <Autocomplete
        id="combo-box"
        options={centers}
        getOptionLabel={option => option.entityName}
        classes={{ option: classes.AutoCompleteText }}
        onChange={sportCenterChange}
        style={{ width: 300 }}
        renderInput={params => (
          <TextField {...params} label="Sucursal/Centro" variant="outlined" fullWidth />
        )}
      />
    );
  }
  return (
    <Fragment>
      <Grid container spacing={3}>
        {/* filtro por centro */}
        <Grid item xs={12} sm={5}>
          {autoCompleteList}
        </Grid>
        <Grid item xs={12} sm={7}>
          <Alert variant="outlined" severity="warning">
            <AlertTitle>Atención!</AlertTitle>
            Si encontrás diferencias con los horarios de tu centro deportivo comunicate con <strong>soporte@tupaseapp.com</strong>
          </Alert>
        </Grid>
      </Grid>
      <br />
      {/* lista de reservas */}
      {timesList}

      <Snackbar
        place="bl"
        color={snackbar.type}
        icon={snackbarIcon[snackbar.type]}
        message={snackbar.message}
        open={snackbar.open}
        closeNotification={() => setSnackbar({ ...snackbar, open: false })}
        close
      />
    </Fragment>
  );
};

export default withStyles(tableStyle)(Times);