import * as actionTypes from './actionTypes';
import axios from '../../axios';

const fetchRolesStart = () => {
  return {
    type: actionTypes.FETCH_ROLES_START,
  };
};

const fetchRolesSuccess = (roles) => {
  return {
    type: actionTypes.FETCH_ROLES_SUCCESS,
    roles: roles,
  };
};

const fetchRolesFail = error => {
  return {
    type: actionTypes.FETCH_ROLES_FAIL,
    error: error
  };
};

export const fetchRoles = token => async dispatch => {
  dispatch(fetchRolesStart());
  try {

   const { data } = await axios.get(`/sbackoffice/entity_users/roles`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }

    );
    dispatch(fetchRolesSuccess(data.roles));
  } catch (err) {
    dispatch(fetchRolesFail(err));
  }
};