import * as actionTypes from '../actions/actionTypes';

const initialState = {
  users: [],
  companys: [],
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USERS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users
      };
    case actionTypes.FETCH_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        users:[]
      };
      case actionTypes.FETCH_COMPANY_START:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case actionTypes.FETCH_COMPANY_SUCCESS:
        return {
          ...state,
          loading: false,
          companys: action.companys
        };
      case actionTypes.FETCH_COMPANY_FAIL:
        return {
          ...state,
          loading: false,
          error: action.error,
          companys:[]
        };
        case actionTypes.ACTIVATE_USERS_SUCCESS:
          return {
            ...state,
            users: state.users.map(user => {
              if (user.userLogicalId === action.userLogicalId) {
                return {
                  ...user,
                  status: 'ACTIVE',
                  statusStr: 'Activo'
                };
              }return user;
    
            })
          
         };  
         case actionTypes.INACTIVATE_USERS_SUCCESS:
          return {
            ...state,
            users: state.users.map(user => {
              if (user.userLogicalId === action.userLogicalId) {
                return {
                  ...user,
                  status: 'INACTIVE',
                  statusStr: 'Inactivo'
                };
              }return user;
    
            })
          
         };                                               
     default:
      return state;
  }
};
