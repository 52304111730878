import * as actionTypes from '../actions/actionTypes';

const initialState = {
  reservationsSatements: [],
  totalsSatements: [],
  loadingStatements: false,
  errorStatements: null,
  dateStatements: null,
  entityStatements:null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_STATEMENTS_START:
      return {
        ...state,
        loadingStatements: true,
        errorStatements: null,
        dateStatements: action.dateStatements,
        entityStatements: action.entityStatements
      };
    case actionTypes.FETCH_STATEMENTS_SUCCESS:
      return {
        ...state,
        loadingStatements: false,
        reservationsSatements: action.statements.reservations,
        totalsSatements: action.statements.totalsList
      };
    case actionTypes.FETCH_STATEMENTS_FAIL:
      return {
        ...state,
        loadingStatements: false,
        errorStatements: action.errorStatements,
        reservationsSatements: [],
        totalsSatements: []
      };
    default:
      return state;
  }
};
