import * as actionTypes from './actionTypes';
import axios from '../../axios';

const fetchStatementsStart =  (date, entity) => {
  return {
    type: actionTypes.FETCH_STATEMENTS_START,
    dateStatements:date,
    entityStatements:entity
  };
};

const fetchStatementsSuccess = statements => {
  return {
    type: actionTypes.FETCH_STATEMENTS_SUCCESS,
    statements: statements
  };
};

const fetchStatementsFail = error => {
  return {
    type: actionTypes.FETCH_STATEMENTS_FAIL,
    errorStatements: error
  };
};

export const fetchStatements = (token, eId, date) => async dispatch => {
  dispatch(fetchStatementsStart(date, eId));
  try {
    
    if(eId!= null && date!=null){
      const period = date.toISOString().substring(0, 7);
      const  {data}  =  await axios.get(
        `/sbackoffice/entity/account-statement/entityId/${eId}/period/${period}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      ); 
      dispatch(fetchStatementsSuccess(data));
    }else{
      dispatch(fetchStatementsSuccess([]));
    }
  } catch (err) {
    dispatch(fetchStatementsFail());
  }
};