/*!

 =========================================================
 * Material Dashboard React - v1.5.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import React from 'react';
import PropTypes from 'prop-types';
// material ui core components
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
// material ui icons
import Edit from '@material-ui/icons/UpdateRounded';
import Cancel from '@material-ui/icons/CancelRounded';
import Confirm from '@material-ui/icons/CheckCircleRounded';
import Description from '@material-ui/icons/Description';
// styles
import tableStyle from '../../../assets/jss/components/tableStyle';

function CustomTable({ ...props }) {
  const { classes, tableHead, tableData, tableHeaderColor } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table} style={props.style}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
            <TableRow>
              {tableHead.map((prop, key) => {
                return prop !== 'Id' ? (
                  <TableCell
                    className={classes.tableCell + ' ' + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                ) : null;
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            const isNotConfirmed =
              prop[prop.length - 1] === 'Pendiente Confirmación';
            const isPCC =
              prop[prop.length - 1] === 'Pendiente Confirmación Cupos';

            return (
              <TableRow key={key}>
                {prop.map((prop, key) => {
                  return key !== 0 ? (
                    <TableCell className={classes.tableCell} key={key}>
                      {prop}
                    </TableCell>
                  ) : null;
                })}
                {props.detail ||
                props.edit ||
                props.delete ||
                props.confirmable ? (
                  <TableCell className={classes.tableActions}>
                    {props.detail ? (
                      <Tooltip
                        id="tooltip-top"
                        title="Detalle"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label="Detalle"
                          className={classes.tableActionButton}
                          onClick={props.onShowDetail.bind(this, prop[0])}
                        >
                          <Description
                            className={
                              classes.tableActionButtonIcon + ' ' + classes.edit
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {props.edit && isPCC ? (
                      <Tooltip
                        id="tooltip-top"
                        title="Confirmar Cupo"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label="Confirmar Cupo"
                          className={classes.tableActionButton}
                          onClick={props.onEdit.bind(this, prop[0])}
                        >
                          <Edit
                            className={
                              classes.tableActionButtonIcon + ' ' + classes.edit
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {props.confirmable && isNotConfirmed ? (
                      <Tooltip
                        id="tooltip-top-start"
                        title="Confirmar Reserva"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label="Confirmar"
                          className={classes.tableActionButton}
                          onClick={props.onConfirm.bind(this, prop[0])}
                        >
                          <Confirm
                            className={
                              classes.tableActionButtonIcon +
                              ' ' +
                              classes.confirm
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                     {props.delete && (isNotConfirmed || isPCC)? (
                      <Tooltip
                        id="tooltip-top-start"
                        title="Cancelar"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label="Cancelar"
                          className={classes.tableActionButton}
                          onClick={props.onDelete.bind(this, prop[0])}
                        >
                          <Cancel
                            className={
                              classes.tableActionButtonIcon +
                              ' ' +
                              classes.close
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </TableCell>
                ) : null}
                
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: 'gray'
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray'
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)),
  detail: PropTypes.bool,
  edit: PropTypes.bool,
  delete: PropTypes.bool,
  onShowDetail: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};

export default withStyles(tableStyle)(CustomTable);
