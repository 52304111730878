import {
    pink, purple, teal, amber, deepOrange,blue, red, deepPurple, indigo,lightBlue,cyan,green,lightGreen, lime, yellow, 
    orange, brown, grey, blueGrey  } from '@material-ui/core/colors';

export const resourcesData = [
    {
      id: 1,
      color: blue,
    }, {
      id: 2,
      color: pink,
    }, {
      id: 3,
      color: purple,
    }, {
      id: 4,
      color: deepOrange,
    }, {
      id: 5,
      color: teal,
    },
    {
      id: 6,
      color: amber,
    },
    {
      id: 7,
      color: red,
    },
    {
      id: 8,
      color: deepPurple,
    },
    {
      id: 9,
      color: indigo,
    },
    {
      id: 10,
      color: lightBlue,
    },
    {
      id: 11,
      color: cyan,
    },
    {
      id: 12,
      color:green,
    },
    {
      id: 13,
      color: lightGreen,
    },
    {
      id: 14,
      color: lime,
    },
    {
      id: 15,
      color: yellow,
    },
    {
      id: 16,
      color: orange,
    },
    {
      id: 17,
      color:brown,
    },
    {
      id: 18,
      color: grey,
    },
    {
      id: 19,
      color: blueGrey,
    },
    {
      id: 20,
      color: pink,
    },
  ];

  export const resources = [
    {
      fieldName: 'groupId',
      title: 'Actividad',
      instances: resourcesData,
    }
  ];