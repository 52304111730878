import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '../../hooks/auth0';

import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, TextField } from '@material-ui/core';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Spinner from '../../components/UI/Spinner/Spinner';
import GridItem from '../../components/UI/Grid/GridItem';
import GridContainer from '../../components/UI/Grid/GridContainer';
import Card from '../../components/UI/Card/Card';
import CardHeader from '../../components/UI/Card/CardHeader';
import CardBody from '../../components/UI/Card/CardBody';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// styles
import AttendanceControlUsers from './AttendanceControlUsers';
import tableStyle from '../../assets/jss/components/tableStyle';
import autoComplete from '../Reservations/Reservations.module.css';

import * as reservationsActions from '../../store/actions/reservations';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  summary: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  details: {
  },
  typography: {
    fontWeight: "bold",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
  },
}));

const AttendanceControl = (props) => {

  const { getIdTokenClaims } = useAuth0();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { centers } = useSelector(state => state.centers);
  const { activities, loading } = useSelector(state => state.activities);

  var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
  const parseDate = date => new Date(date).toLocaleString('es-ES', options);

  const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    if (newExpanded) {
      fetchActivitiesUsers(null, null, panel);
    }
  };

  useEffect(() => {
     if(centers.length === 0){
      fetchSportsCenters();
    }      
    if (centers.length === 1) {
      fetchActivities(centers[0].entityId);
    }
  }, []);

  const fetchSportsCenters = async () => {
    const { __raw } = await getIdTokenClaims();
    dispatch(reservationsActions.fetchTrainerSportsCenters(__raw));
  };

  const fetchActivities = async (entityId) => {
    const { __raw } = await getIdTokenClaims();
    dispatch(reservationsActions.fetchActivities(__raw, entityId));
  };
  const fetchActivitiesUsers = async (entityId, date, activity) => {
    const { __raw } = await getIdTokenClaims();
    dispatch(reservationsActions.fetchActivitiesUsers(__raw, entityId, date, activity));
    dispatch(reservationsActions.fetchWaitingUsers(__raw, entityId, activity));
  };

  function sportCenterChange(event, value) {
    let eID = null;
    if (value) {
      eID = value.entityId;
    }
    fetchActivities(eID);
  };

  let actividadesList = null;
  if (loading) {
    actividadesList = <Spinner />;
  } else {
    if (activities && activities.length > 0) {
      actividadesList = (
          <GridContainer >
            <GridItem xs={12}> 
              <Card>
                <CardHeader color="primary">
                  <h4 className={props.classes.cardTitleWhite}>Control de Asistencia</h4>
                </CardHeader>
                <CardBody>
                  {activities.map(event => (
                    <Accordion square TransitionProps={{ unmountOnExit: true }}
                      expanded={expanded === event.idActividad}
                      className={classes.root}
                      onChange={handleChange(event.idActividad)}>
                      <AccordionSummary
                        className={classes.summary}
                        id="panel1d-header"
                        expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.typography}>
                          {parseDate(event.fechaActividad)} <br></br>
                          {event.actividad}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <AttendanceControlUsers rowData={event}></AttendanceControlUsers>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
      );
    } else {
      //no hay actividades
      actividadesList = (
        <Alert variant="outlined" severity="info">
          <AlertTitle>Atención!</AlertTitle>
          Debe seleccionar un centro deportivo para ver sus actividades o el centro seleccionado no tiene actividades registradas.
          <span>Cualquier consulta comunicate con <strong>soporte@tupaseapp.com</strong></span>
        </Alert>
      );
    }
  }
  let autoCompleteList = null;
    
  if (centers.length > 1) {
    autoCompleteList = (
      <Autocomplete
      id="sucursales"
      options={centers}
      getOptionLabel={option => option.entityName}
      onChange = {sportCenterChange}
      classes = {{option: autoComplete.AutoCompleteText}}
      renderInput={params => (
        <TextField {...params} label="Sucursal/Centro" variant="outlined" fullWidth />
      )}
    />
    );
  }
  return (
    <Fragment>
    <Grid container spacing={2}>
      {/* filtro por centro */}
      <Grid item xs={12} sm={3}>{autoCompleteList}</Grid>
    </Grid>
    <br />
     {actividadesList}
    </Fragment>

  );
};

export default withStyles(tableStyle)(AttendanceControl);
