/*!

 =========================================================
 * Material Dashboard React - v1.5.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import React, { Component, Fragment } from 'react';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
// material ui core components
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
// material ui icons
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Description from '@material-ui/icons/Description';
import DragHandle from '@material-ui/icons/DragHandle';
// styles
import tableStyle from '../../../assets/jss/components/tableStyle';

const SortableItem = SortableElement(({ tableHead, classes, key, prop, onShowDetail, onEdit, onDelete }) => {
  return (
    <Fragment>
      {
        prop.map((prop, key) => {
          return (
            key === 1 ?
              <TableCell className={classes.tableCell} key={key}>
                <Tooltip
                  id="tooltip-top"
                  title="Arrastrar para ordenar"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <DragHandle
                    className={
                      classes.tableActionButtonIcon + " " + classes.edit + " " + classes.handle
                    }
                  />
                </Tooltip>
                <span style={{ marginLeft: '10px' }}>{prop}</span>
              </TableCell>
              :
              key !== 0 ?
                <TableCell className={classes.tableCell} key={key}>
                  {prop}
                </TableCell> :
                null
          );
        })
      }
    </Fragment>
  )

});

const SortableList = SortableContainer(({ items, classes, tableHead, tableHeaderColor, onShowDetail, onEdit, onDelete }) => {

  return (
    <Table className={classes.table}>
      {tableHead !== undefined ? (
        <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
          <TableRow>
            {tableHead.map((prop, key) => {
              return (
                prop !== "Id" ?
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop !== "Handle" ? prop : null}
                  </TableCell> : null
              );
            })}
          </TableRow>
        </TableHead>
      ) : null}
      <TableBody>
        {items.map((prop, key) => {
          return (
            <TableRow key={key}>
              <SortableItem key={key} index={key} prop={prop} classes={classes} onShowDetail={onShowDetail} onEdit={onEdit} onDelete={onDelete} />
              {this.props.details || this.props.edit || this.props.delete ?
                <TableCell className={classes.tableActions}>
                  {this.props.detail ?
                    <Tooltip
                      id="tooltip-top"
                      title="Detalle"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label="Detalle"
                        className={classes.tableActionButton}
                        onClick={onShowDetail.bind(this, prop[0])}
                      >
                        <Description
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip> : null}
                  {this.props.edit ?
                    <Tooltip
                      id="tooltip-top"
                      title="Editar"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label="Editar"
                        className={classes.tableActionButton}
                        onClick={onEdit.bind(this, prop[0])}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip> : null}
                  {this.props.delete ?
                    <Tooltip
                      id="tooltip-top-start"
                      title="Eliminar"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label="Eliminar"
                        className={classes.tableActionButton}
                        onClick={onDelete.bind(this, prop[0])}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + " " + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip> : null}
                </TableCell> : null}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  )
});

class TableList extends Component {

  state = {
    items: this.props.tableData
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      items: arrayMove(this.state.items, oldIndex, newIndex),
    });
    this.props.onSortEnd(this.state.items);
  };

  shouldCancelStart = (e) => {
    // Cancel sorting if the event target is any of this elements
    const disabledElements = ['input', 'textarea', 'select', 'option', 'button', 'svg', 'path', 'span'];

    if (disabledElements.indexOf(e.target.tagName.toLowerCase()) !== -1) {
      return true; // Return true to cancel sorting
    }
  };

  render() {

    const { classes, tableHead } = this.props;
    return (
      <div className={classes.tableResponsive}>
        <SortableList
          items={this.state.items}
          tableHead={tableHead}
          tableHeaderColor
          classes={classes}
          detail={this.props.detail}
          edit={this.props.edit}
          delete={this.props.delete}
          onShowDetail={this.props.onShowDetail}
          onEdit={this.props.onEdit}
          onDelete={this.props.onDelete}
          onSortEnd={this.onSortEnd} />
      </div>
    )
  }
}

TableList.defaultProps = {
  tableHeaderColor: "gray"
};

TableList.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  detail: PropTypes.bool,
  edit: PropTypes.bool,
  delete: PropTypes.bool,
  onShowDetail: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSortEnd: PropTypes.func
};

export default withStyles(tableStyle)(TableList);
