/*!

 =========================================================
 * Material Dashboard React - v1.5.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// components
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import Table from '../Table/Table';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import SortableTable from '../Table/SortableTable';
// material ui core components
import withStyles from '@material-ui/core/styles/withStyles';
// styles
import listsContainer from '../../../assets/jss/containers/listsContainer';

function TableList(props) {
  const { classes } = props;
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>{props.title}</h4>
          </CardHeader>
          <CardBody>
            {props.sortable ? (
              <SortableTable
                tableHeaderColor="primary"
                tableHead={props.head}
                tableData={props.data}
                detail={props.detail}
                edit={props.edit}
                delete={props.delete}
                onShowDetail={props.onShowDetail}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
                onSortEnd={props.onSortEnd}
              />
            ) : (
              <Table
                tableHeaderColor="primary"
                tableHead={props.head}
                tableData={props.data}
                style={props.style}
                detail={props.detail}
                edit={props.edit}
                delete={props.delete}
                confirmable={props.confirmable}
                onShowDetail={props.onShowDetail}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
                onConfirm={props.onConfirm}
              />
            )}
            {props.pagination}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

TableList.propTypes = {
  title: PropTypes.string,
  head: PropTypes.array,
  data: PropTypes.array,
  detail: PropTypes.bool,
  sortable: PropTypes.bool,
  edit: PropTypes.bool,
  delete: PropTypes.bool,
  onShowDetail: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSortEnd: PropTypes.func,
  pagination: PropTypes.element
};

export default withRouter(withStyles(listsContainer)(TableList));
