import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as reservationsActions from '../../../store/actions/reservations';
import * as statementsActions from '../../../store/actions/statements';
import { useAuth0 } from '../../../hooks/auth0';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import deLocale from "date-fns/locale/es";

function YearMonthPicker(props) {
  const [selectedDate, setSelectedDate] = useState(null);
  const dispatch = useDispatch();
  const { getIdTokenClaims } = useAuth0();

  const { entity } = useSelector(state => state.entity);
  const { entityStatements } = useSelector(state => state.entityStatements);
  const { status } = useSelector(state => state.status);

  const onDateChange = val => {
    setSelectedDate(val);
    fetchReservations(val)
  };

  const fetchReservations = async (sDate) => {
    const { __raw } = await getIdTokenClaims();
    if (props.isAdmin) {
      dispatch(reservationsActions.fetchPendingQuota(__raw, entity, status, sDate));
    } else {
      if (props.isStatements) {
        dispatch(statementsActions.fetchStatements(__raw, entityStatements, sDate));
      } else {
        dispatch(reservationsActions.fetchReservations(__raw, entity, sDate,status));
      }
    }

  };

  return (
    <Fragment>
      <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
        <DatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          openTo="month"
          views={["year", "month"]}
          label="Mes/Año"
          helperText="Seleccione mes/año que desea ver"
          value={selectedDate}
          onChange={onDateChange}
        />
      </MuiPickersUtilsProvider>
    </Fragment>
  );
}
YearMonthPicker.propTypes = {
  isAdmin: PropTypes.bool,
  isStatements: PropTypes.bool
};
export default YearMonthPicker;
