import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as reservationsActions from '../../store/actions/reservations';
import { useAuth0 } from '../../hooks/auth0';
import logoImage from '../../assets/images/tupase-logoApp.png';

// material ui components
import {
  TextField,
  Grid
} from '@material-ui/core';
import {
  CheckCircle as SuccessIcon,
  Error as DangerIcon
  } from '@material-ui/icons';
// components
import TableList from '../../components/UI/TableList/TableList';
import YearMonthPicker from '../../components/UI/Date/YearMonthPicker';
import Spinner from '../../components/UI/Spinner/Spinner';
import Pagination from '../../components/UI/Pagination/Pagination';
import Snackbar from '../../components/UI/Snackbar/Snackbar';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';

// styles
import classes from './Reservations.module.css';

const PAGE_LIMIT = 20;

const statuses = [
  { value: 'WAITING_QUOTA', label: 'En Espera' },
  { value: 'QUOTA_VALIDATION_REQUIRED', label: 'Pendiente validación cupo' },
  { value: 'PENDING_CONFIRMATION', label: 'Pendiente Confirmación' },
  { value: 'CONFIRMED', label: 'Confirmada' },
  { value: 'CREATED_MANUALLY', label: 'Creada Manualmente' },
  { value: 'CANCELLED', label: 'Cancelada' }
]
var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
const parseDate = date => new Date(date).toLocaleString('es-ES', options);
const subscription = subs => (subs === 'TuPase') ? <div className={classes.Logo}> <img src={logoImage} alt="TuPase Admin" /></div> : null;

const snackbarIcon = {
  success: SuccessIcon,
  danger: DangerIcon
};

const ReservationsAdmin = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'success'
  });

  const { getIdTokenClaims } = useAuth0();

  const dispatch = useDispatch();
  const { reservations, loading } = useSelector(state => state.reservations);
  const { centers } = useSelector(state => state.centers);
  const { date } = useSelector(state => state.date);
  const { entity } = useSelector(state => state.entity);
  const { status } = useSelector(state => state.status);


  useEffect(() => {
    if (centers.length === 0) {
      fetchSportsCenters();
    }
    fetchPendingQuota();
  }, []);

  const fetchPendingQuota = async (entityId, date1, status) => {
    const { __raw } = await getIdTokenClaims();
    dispatch(reservationsActions.fetchPendingQuota(__raw, entityId, status, date1));
  };

  const fetchSportsCenters = async () => {
    const { __raw } = await getIdTokenClaims();
    dispatch(reservationsActions.fetchSportsCenters(__raw));
  };

  const pageClickHandler = ({ currentPage }) => {
    setCurrentPage(currentPage);
  };

  function sportCenterChange(event, value) {
    if (value) {
      fetchPendingQuota(value.entityId, date, status);
    } else {
      fetchPendingQuota(undefined, date, status);
    }
  };

  function statusChange(event, value) {
    if (value) {
      fetchPendingQuota(entity, date, value.value);

    } else {
      fetchPendingQuota(entity, date);
    }
  }

  const confirmQuotaHandler = id => {
    const reservation = reservations.find(reservation => reservation.id === id);
    confirmQuota(reservation);
  };


  const confirmReservationHandler = id => {
    const reservation = reservations.find(reservation => reservation.id === id);
    confirmReservation(reservation);
  };

  const cancelReservationHandler = async id => {
    const reservation = reservations.find(reservation => reservation.id === id);
    cancelReservation(reservation);
  };

  const confirmReservation = async (reservation) => {
    const { id, codActivacion, documentoUsuario, fechaActividad } = reservation;
    try {
      const { __raw } = await getIdTokenClaims();
      await dispatch(
        reservationsActions.confirmReservation(
          __raw,
          id,
          codActivacion,
          documentoUsuario,
          fechaActividad
        )
      );
      setSnackbar({
        open: true,
        message: 'La reserva fue confirmada con éxito',
        type: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response.data.showMessage,
        type: 'danger'
      });
    }
  };

  const cancelReservation = async (reservation) => {
    const { id, codActivacion, idActividad, fechaActividad, documentoUsuario} = reservation;
    try {
      const { __raw } = await getIdTokenClaims();
      await dispatch(
        reservationsActions.cancelReservation(
          __raw,
          id,
          codActivacion,
          idActividad,
          fechaActividad,
          documentoUsuario
        )
      );
      setSnackbar({
        open: true,
        message: 'La reserva fue cancelada con éxito',
        type: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response.data.showMessage,
        type: 'danger'
      });
    }
  };

  const confirmQuota = async (reservation) => {
    const { id, documentoUsuario, idActividad } = reservation;
    try {
      const { __raw } = await getIdTokenClaims();
      await dispatch(
        reservationsActions.confirmQuota(
          __raw,
          id,
          documentoUsuario,
          idActividad
        )
      );
      setSnackbar({
        open: true,
        message: 'Cupo confirmado con éxito',
        type: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response.data.showMessage,
        type: 'danger'
      });
    }
  };

  let reservationsList = null;
  if (loading) {
    reservationsList = <Spinner />;
  } else {
    if (reservations.length > 0) {
      const currentPageReservations = reservations.slice(
        (currentPage - 1) * PAGE_LIMIT,
        currentPage * PAGE_LIMIT
      );
      const reservationsData = currentPageReservations.map(reservation => {
        return [
          reservation.id,
          parseDate(reservation.fechaActividad),
          reservation.nombreUsuario,
          reservation.documentoUsuario,
          reservation.actividad,
          reservation.centro,
          subscription(reservation.subscriptionPlatform),
          reservation.estado
        ];
      });

      reservationsList = (
        <TableList
          title="Gestión reservas"
          style={{ minWidth: '768px' }}
          head={[
            'Id',
            'Fecha de reserva',
            'Nombre completo',
            'Documento',
            'Actividad',
            'Centro',
            '',
            'Estado',
            'Acciones'
          ]}
          data={reservationsData}
          confirmable
          onConfirm={confirmReservationHandler}
          delete
          onDelete={cancelReservationHandler}
          edit
          onEdit={confirmQuotaHandler}
          pagination={
            <Pagination
              totalRecords={reservations.length}
              pageLimit={PAGE_LIMIT}
              pageNeighbours={15}
              onPageChanged={pageClickHandler}
            />
          }
        />
      );
    } else {
      //no hay reservas para el centro logueado.
      reservationsList = (
        <Alert variant="outlined" severity="info">
          <AlertTitle>Atención!</AlertTitle>
          No existen reservas para el centro deportivo seleccionado.
        </Alert>
      );
    }

  }
  let autoCompleteList = null;

  if (centers.length > 1) {
    autoCompleteList = (
      <Autocomplete
        id="combo-box-demo"
        options={centers}
        getOptionLabel={option => option.entityName}
        onChange={sportCenterChange}
        classes={{ option: classes.AutoCompleteText }}
        renderInput={params => (
          <TextField {...params} label="Sucursal/Centro" variant="outlined" fullWidth />
        )}
      />
    );
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        {/* filtro por mes/año */}
        <Grid item xs={12} sm={3} xl={2}><YearMonthPicker isAdmin /></Grid>
        {/* filtro por centro */}
        <Grid item xs={12} sm={3} xl={3}>{autoCompleteList}</Grid>
        <Grid item xs={12} sm={3} xl={3}>
          <Autocomplete
            id="estados"
            options={statuses}
            getOptionLabel={option => option.label}
            onChange={statusChange}
            classes={{ option: classes.AutoCompleteText }}
            renderInput={params => (
              <TextField {...params} label="Estado reserva" variant="outlined" fullWidth />
            )}
          />
        </Grid>
      </Grid>
      <br></br>
      {/* lista de reservas */}
      {reservationsList}


      <Snackbar
        place="bl"
        color={snackbar.type}
        icon={snackbarIcon[snackbar.type]}
        message={snackbar.message}
        open={snackbar.open}
        closeNotification={() => setSnackbar({ ...snackbar, open: false })}
        close
      />
    </Fragment>
  );
};

export default ReservationsAdmin;
