import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useAuth0 } from '../hooks/auth0';
import withWidth from '@material-ui/core/withWidth';
// components
import Spinner from '../components/UI/Spinner/Spinner';
// HOC
import Layout from '../hoc/Layout/Layout';
import FullScreenLayout from '../hoc/Layout/FullScreenLayout/FullScreenLayout';
// routes
import indexRoutes from '../routes/index';
// styles
import './App.module.css';

import  UserReservation from './Users/UserReservations';

const App = props => {
  const { loading, isAuthenticated, logout } = useAuth0();
  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw'
        }}
      >
        <Spinner />
      </div>
    );
  }

  const customBaseRef =
    process.env.REACT_APP_BASE_REF !== '/'
      ? process.env.REACT_APP_BASE_REF
      : '';

  return (
    <Switch>
      <Route path="/login" component={FullScreenLayout} />
      <Route path="/userReservation/:userLogicalId/:userName" component={UserReservation} />
      {isAuthenticated ? (
        <Layout
          onLogout={() =>
            logout({
              returnTo: `${window.location.origin + customBaseRef}/login`
            })
          }
        >
          <Switch>
            {indexRoutes.map((prop, key) => {
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
             {<Redirect from="/" to="/reservas" /> }
          </Switch>
        </Layout>
      ) : (
        <Redirect to="/login" />
      )}
    </Switch>
  );
};

export default (withWidth()) (App);
