import React, { useState, Fragment } from 'react';
import QrReader from 'react-qr-reader';
import * as reservationsActions from '../../store/actions/reservations';
import { useAuth0 } from '../../hooks/auth0';
import { useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import GridContainer from '../../components/UI/Grid/GridContainer';
import GridItem from '../../components/UI/Grid/GridItem';
import CardHeader from '../../components/UI/Card/CardHeader';
import CardBody from '../../components/UI/Card/CardBody';
import tableStyle from '../../assets/jss/components/tableStyle';
import { withStyles } from '@material-ui/core/styles';

const QrConfirmation = (props) => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState("");
  const [alert, setAlert] = useState({
    message: '',
    type: 'success'
  });

  const { getIdTokenClaims } = useAuth0();
  const dispatch = useDispatch();

  const handleScan = (data) => {
    if (data) {
      setData(data);
      let params = data.split('#'); // 
      var code = params[0];
      var doc = params[1];
      var date = params[2];
      confirmReservationHandler(code, doc, date);
    }
  };

  const confirmReservationHandler = async (confirmationCode, documentoUsuario, fechaActividad) => {

    try {
      const { __raw } = await getIdTokenClaims();
      await dispatch(
        reservationsActions.confirmReservation(
          __raw,
          null,
          confirmationCode,
          documentoUsuario,
          fechaActividad
        )
      );
      setOpen(true);
      setAlert({
        message: 'La reserva fue confirmada con éxito',
        type: 'success'
      });
    } catch (error) {
      setOpen(true);
      setAlert({
        message: error.response.data.showMessage,
        type: 'error'
      });
    }
  };

  const previewStyle = {
    height: 240,
    width: 320,
    marginLeft: "20px",
  }

  const handleError = (err) => console.error(err);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  let qr = null;
  qr = (
    <div >
      <br></br>
      <GridContainer >
        <GridItem xs={12}>
          <CardHeader color="primary">
            <h4 className={props.classes.cardTitleWhite}>Confirmación por QR</h4>
          </CardHeader>
          <CardBody></CardBody>
          <QrReader
            delay={300}
            onScan={handleScan}
            onError={handleError}
            style={previewStyle}
          />
          </GridItem>
      </GridContainer>
    </div>
  );
  return (
    <Fragment>
      {qr}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert variant="filled" severity={alert.type}>
          {alert.message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default withStyles(tableStyle)(QrConfirmation);