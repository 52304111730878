import * as actionTypes from './actionTypes';
import axios from '../../axios';

const fetchUsersStart = () => {
  return {
    type: actionTypes.FETCH_USERS_START,
    users: []
  };
};

const fetchComapnyStart = () => {
  return {
    type: actionTypes.FETCH_COMPANY_START,
    companys: []
  };
};

const fetchUsersSuccess = (users) => {
  return {
    type: actionTypes.FETCH_USERS_SUCCESS,
    users: users
  };
};

const fetchCompanySuccess = (companys) => {
  return {
    type: actionTypes.FETCH_COMPANY_SUCCESS,              
    companys: companys
  };
};


const fetchUsersFail = error => {
  return {
    type: actionTypes.FETCH_USERS_FAIL,
    users: [],
    error: error
  };
};

const fetchCompanyFail = error => {
  return {
    type: actionTypes.FETCH_COMPANY_FAIL,
    companys: [],
    error: error
  };
};

const activateUserSuccess = userLogicalId => {
  return {
    userLogicalId: userLogicalId,
    type: actionTypes.ACTIVATE_USERS_SUCCESS
  };
};

const inactivateUserSuccess = userLogicalId => {
  return {
    userLogicalId: userLogicalId,
    type: actionTypes.INACTIVATE_USERS_SUCCESS
  };
};

export const fetchCompanys = (token) => async dispatch => {
  dispatch(fetchComapnyStart());
  try {
    const { data } = await axios.get(
      `/sbackoffice/company_users`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    dispatch(fetchCompanySuccess(data));
    if (data.length === 1) {
      dispatch(fetchUsers(token, data[0].companyId));
    }


  } catch (err) {
    dispatch(fetchCompanyFail(err));
  }
};

export const fetchUsers = (token, companyId) => async dispatch => {
  dispatch(fetchUsersStart());
  try {
    const { data } = await axios.get(
      `/sbackoffice/users`,
      {
        params: {
          _companyId: companyId
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    dispatch(fetchUsersSuccess(data));

  } catch (err) {
    dispatch(fetchUsersFail(err));
  }
};

export const searchUser = (token, nombre, apellido, documento) => async dispatch => {
  dispatch(fetchUsersStart());
  try {
    const { data } = await axios.get(
      `/sbackoffice/users/search`,
      {
        params: {
          _name: nombre,
          _lastName: apellido,
          _document: documento
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    dispatch(fetchUsersSuccess(data));

  } catch (err) {
    dispatch(fetchUsersFail(err));
  }
};

export const activeUser= (
  token,
  userLogicalId
  ) => async dispatch => {
  try {
    await axios.post(
      `/sbackoffice/users/active/${userLogicalId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
    }
    );
    dispatch(activateUserSuccess(userLogicalId));
  } catch (err) {
    throw err;
  }
};

export const inactiveUser= (
  token,
  userLogicalId
  ) => async dispatch => {
  try {
    await axios.post(
      `/sbackoffice/users/inactive/${userLogicalId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
    }
    );
    dispatch(inactivateUserSuccess(userLogicalId));
  } catch (err) {
    throw err;
  }
};