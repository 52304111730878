/*!

 =========================================================
 * Material Dashboard React - v1.5.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// components
import HeaderLinks from './HeaderLinks/HeaderLinks';
// material ui core components
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
// styles
import headerStyle from '../../assets/jss/components/headerStyle.jsx';
import { useAuth0 } from '../../hooks/auth0';

const Header = (props) => {

  function makeBrand() {
    var name = "";
    props.routes.map((prop, key) => {
      if (prop.path === props.location.pathname) {
        name = prop.navbarName;
      }
      return null;
    });
    return name;
  }
  
  const { user } = useAuth0();
  const { classes, color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  return (
    <Hidden mdDown>
      <AppBar className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button color="transparent" href="#" className={classes.title}>
            {makeBrand()}
          </Button>
        </div>
        <div className={classes.title}>
          <p>{user.name}</p>
          
        </div>
          <Hidden smDown implementation="css">
            <HeaderLinks onLogout={props.onLogout} />
          </Hidden>
        </Toolbar>
      </AppBar>
    </Hidden>
  );
}

Header.propTypes = {
  routes: PropTypes.array
};

export default withRouter(withStyles(headerStyle)(Header));