import * as actionTypes from './actionTypes';
import axios from '../../axios';

const fetchReservationsStart = (date, entity, status) => {
  return {
    type: actionTypes.FETCH_RESERVATIONS_START,
    date: date,
    entity: entity,
    status: status
  };
};

const fetchActivitiesStart = (entity) => {
  return {
    type: actionTypes.FETCH_ACTIVITIES_START,
    entity: entity,
  };
};

const fetchActivitiesUsersStart = (entity) => {
  return {
    type: actionTypes.FETCH_ACTIVITIES_USERS_START,
    entity: entity
  };
};

const fetchSportsCentersStart = () => {
  return {
    type: actionTypes.FETCH_CENTERS_START
  };
};

const fetchReservationsSuccess = (reservations) => {
  return {
    type: actionTypes.FETCH_RESERVATIONS_SUCCESS,
    reservations: reservations,
  };
};

const fetchActivitiesSuccess = (activities) => {
  return {
    type: actionTypes.FETCH_ACTIVITIES_SUCCESS,
    activities: activities
  };
};

const fetchActivitiesUsersSuccess = (activitiesUsers) => {
  return {
    type: actionTypes.FETCH_ACTIVITIES_USERS_SUCCESS,
    activitiesUsers: activitiesUsers
  };
};

const fetchWaitingsUsersSuccess = (waitingUsers) => {
  return {
    type: actionTypes.FETCH_WAITING_USERS_SUCCESS,
    waitingUsers: waitingUsers
  };
};

const fetchSportsCentersSuccess = centers => {
  return {
    type: actionTypes.FETCH_CENTERS_SUCCESS,
    centers: centers
  };
};

const fetchReservationsFail = error => {
  return {
    type: actionTypes.FETCH_RESERVATIONS_FAIL,
    reservations:[],
    error: error
  };
};

const fetchActivitiesFail = error => {
  return {
    type: actionTypes.FETCH_ACTIVITIES_FAIL,
    error: error
  };
};

const fetchActivitiesUsersFail = error => {
  return {
    type: actionTypes.FETCH_ACTIVITIES_USERS_FAIL,
    error: error
  };
};

const fetchSportsCentersFail = error => {
  return {
    type: actionTypes.FETCH_CENTERS_FAIL,
    error: error
  };
};

export const fetchPendingQuota = (token, eId, status, date) => async dispatch => {
  dispatch(fetchReservationsStart(date, eId, status));
  try {
    if (date) {
      const startDate = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
      const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString();


      const { data } = await axios.get(
        `/sbackoffice/entity/reservations/admin?_end=300&_order=DESC&_sort=fechaActividad&_start=0`,
        {
          params: {
            _startDate: startDate.substring(0, 10),
            _endDate: endDate.substring(0, 10),
            _entityId: eId,
            _status: status
          },
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      dispatch(fetchReservationsSuccess(data));
    } else {

      const { data } = await axios.get(
        `/sbackoffice/entity/reservations/admin?_end=300&_order=DESC&_sort=fechaActividad&_start=0`,
        {
          params: {
            _entityId: eId,
            _status: status
          },
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      dispatch(fetchReservationsSuccess(data));
    }
  } catch (err) {
    dispatch(fetchReservationsFail(err));
  }
};

export const fetchReservations = (token, eId, date, status) => async dispatch => {
  dispatch(fetchReservationsStart(date, eId, status));
  try {
    if (date) {
      const startDate = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
      const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString();


      const { data } = await axios.get(
        `/sbackoffice/entity/reservations?_end=300&_order=DESC&_sort=fechaActividad&_start=0`,
        {
          params: {
            _startDate: startDate.substring(0, 10),
            _endDate: endDate.substring(0, 10),
            _entityId: eId,
            _status: status
          },
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      dispatch(fetchReservationsSuccess(data));
    } else {

      const { data } = await axios.get(
        `/sbackoffice/entity/reservations?_end=300&_order=DESC&_sort=fechaActividad&_start=0`,
        {
          params: {
            _entityId: eId,
            _status: status
          },
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      dispatch(fetchReservationsSuccess(data));
    }
  } catch (err) {
    dispatch(fetchReservationsFail(err));
  }
};

export const fetchSportsCenters = token => async dispatch => {
  dispatch(fetchSportsCentersStart());
  try {
    const { data } = await axios.get(
      '/sbackoffice/entity_users',
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }

    );
    dispatch(fetchSportsCentersSuccess(data));
  } catch (err) {
    dispatch(fetchSportsCentersFail(err));
  }
};

export const fetchTrainerSportsCenters = token => async dispatch => {
  dispatch(fetchSportsCentersStart());
  try {
    const { data } = await axios.get(
      '/sbackoffice/entity_users/entity_trainer',
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }

    );
    dispatch(fetchSportsCentersSuccess(data));
  } catch (err) {
    dispatch(fetchSportsCentersFail(err));
  }
};

const confirmReservationSuccess = reservationId => {
  return {
    reservationId: reservationId,
    type: actionTypes.CONFIRM_RESERVATION_SUCCESS
  };
};

const confirmReservationUserSuccess = reservationId => {
  return {
    reservationId: reservationId,
    type: actionTypes.CONFIRM_RESERVATION_USER_SUCCESS
  };
};

const cancelReservationSuccess = reservationId => {
  return {
    reservationId: reservationId,
    type: actionTypes.CANCEL_RESERVATION_SUCCESS
  };
};

const confirmQuotaSuccess = reservationId => {
  return {
    reservationId: reservationId,
    type: actionTypes.CONFIRM_QUOTA_SUCCESS
  };
};

export const confirmReservation = (
  token,
  reservationId,
  confirmationCode,
  clientDocument,
  activityDate
) => async dispatch => {
  try {
    await axios.post(
      '/sbackoffice/entity/reservations',
      {
        codigoConfirmacion: confirmationCode,
        documentoIdentidadCliente: clientDocument,
        fechaActividad: activityDate
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    dispatch(confirmReservationSuccess(reservationId));
  } catch (err) {
    throw err;
  }
};

export const confirmReservationUser = (
  token,
  reservationId,
  confirmationCode,
  clientDocument,
  activityDate
) => async dispatch => {
  try {
    await axios.post(
      '/sbackoffice/entity/reservations',
      {
        codigoConfirmacion: confirmationCode,
        documentoIdentidadCliente: clientDocument,
        fechaActividad: activityDate
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    dispatch(confirmReservationUserSuccess(reservationId));
  } catch (err) {
    throw err;
  }
};

export const createReservationUser= (
  token,
  documentoUsuario,
  idActividad
  ) => async dispatch => {
  try {
    await axios.post(
      '/sbackoffice/entity/reservations/create',
      {
        documentoIdentidadCliente: documentoUsuario,
        idActividad: idActividad,
        manual:true
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    dispatch(fetchActivitiesUsers(token, null, null, idActividad));
  } catch (err) {
    throw err;
  }
};

export const confirmQuota = (
  token,
  reservationId,
  clientDocument,
  activityId
) => async dispatch => {
  try {
    await axios.post(
      '/users/reservations/confirm-quota',
      {
        idActividad: activityId,
        documentoIdentidadCliente: clientDocument
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    dispatch(confirmQuotaSuccess(reservationId));
  } catch (err) {
    throw err;
  }
};

export const cancelReservation = (
  token,
  reservationId,
  confirmationCode,
  activityId,
  activityDate,
  userDocument
) => async dispatch => {
  try {
    await axios.post(
      '/users/reservations/cancel',
      {
        reservationIdentityId: confirmationCode,
        activityId: activityId,
        activityDate: activityDate,
        userDocument:userDocument,
        force: true,
        sendNotification: true
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    dispatch(cancelReservationSuccess(reservationId));
  } catch (err) {
    throw err;
  }
};

export const fetchActivities = (token, eId) => async dispatch => {
  dispatch(fetchActivitiesStart(eId));
  try {
    const { data } = await axios.get(
      `/sbackoffice/entity/reservations/trainer?_end=300&_order=ASC&_sort=fechaActividad&_start=0`,
      {
        params: {
          _entityId: eId,
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    dispatch(fetchActivitiesSuccess(data));
  } catch (err) {
    dispatch(fetchActivitiesFail(err));
  }
};

export const fetchActivitiesUsers = (token, eId, date, activity) => async dispatch => {
  dispatch(fetchActivitiesUsersStart(eId));
  try {
    const statuses = "PENDING_CONFIRMATION," + "CONFIRMED," + "CREATED_MANUALLY";
    const { data } = await axios.get(
      `/sbackoffice/entity/reservations/activityGlobalId`,
      {
        params: {
          _activityGlobalId: activity,
          _statuses:statuses
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    dispatch(fetchActivitiesUsersSuccess(data));
  } catch (err) {
    dispatch(fetchActivitiesUsersFail(err));
  }
};


export const fetchWaitingUsers = (token, eId, activity) => async dispatch => {
  dispatch(fetchActivitiesUsersStart(eId));
  try {
    const statuses = "WAITING_QUOTA";
    const { data } = await axios.get(
      `/sbackoffice/entity/reservations/activityGlobalId`,
      {
        params: {
          _activityGlobalId: activity,
          _statuses:statuses
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    dispatch(fetchWaitingsUsersSuccess (data));
  } catch (err) {
    dispatch(fetchActivitiesUsersFail(err));
  }
};

export const fetchReservationsUser = (token, userLogicalId) => async dispatch => {
  dispatch(fetchReservationsStart());
  try {
      const { data } = await axios.get(
        `/users/reservations/find/user/${userLogicalId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      dispatch(fetchReservationsSuccess(data));
  } catch (err) {
    dispatch(fetchReservationsFail(err));
  }
};