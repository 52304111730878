/*!

 =========================================================
 * Material Dashboard React - v1.5.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
} from "../material-dashboard-react.jsx";

const tableStyle = theme => ({
  warningTableHeader: {
    color: warningColor
  },
  primaryTableHeader: {
    color: primaryColor
  },
  dangerTableHeader: {
    color: dangerColor
  },
  successTableHeader: {
    color: successColor
  },
  infoTableHeader: {
    color: infoColor
  },
  roseTableHeader: {
    color: roseColor
  },
  grayTableHeader: {
    color: grayColor
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "separate",
  },
  tableHeadCell: {
    color: "grey",
    fontSize: "1em",
    fontWeight: "bold",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
  },
  tableCell: {
    color: "grey",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
   },
   tableCell1: {
      color: "grey",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      lineHeight: "1.42857143",
      textDecoration:" line-through",
      fontStyle: "italic",
      padding: "12px 8px",
      verticalAlign: "middle"
  },
  tableResponsive: {
    width: "100%",
    overflowX: "auto"
  },
  tableActionButton: {
    width: "30px",
    height: "30px",
    padding: "0"
  },
  tableActionButtonIcon: {
    width: "22px",
    height: "22px"
  },
  paginationTableRow: {
    textAlign: 'center'
  },
  edit: {
    backgroundColor: "transparent",
    color: successColor,
    boxShadow: "none"
  },
  handle: {
    cursor: 'pointer',
    fontSize: '30px',
    width: '20px',
    height: '20px',
    verticalAlign: 'middle'
  },
  close: {
    backgroundColor: "transparent",
    color: dangerColor,
    boxShadow: "none"
  },
  confirm: {
    backgroundColor: "transparent",
    color: successColor,
    boxShadow: "none"
  } ,
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  successTableCell: {
    fontWeight: "bold",
    color: successColor, /*"#46EBB4", */
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
  }
});

export default tableStyle;
