import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Workbook from 'react-xlsx-workbook';
import * as reservationsActions from '../../store/actions/reservations';
import { useAuth0 } from '../../hooks/auth0';
import logoImage from '../../assets/images/tupase-logoApp.png';

// material ui components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid
} from '@material-ui/core';
import {
  CheckCircle as SuccessIcon,
  Error as DangerIcon
} from '@material-ui/icons';
// components
import TableList from '../../components/UI/TableList/TableList';
import YearMonthPicker from '../../components/UI/Date/YearMonthPicker';
import Spinner from '../../components/UI/Spinner/Spinner';
import Pagination from '../../components/UI/Pagination/Pagination';
import Snackbar from '../../components/UI/Snackbar/Snackbar';
import Button from '../../components/UI/CustomButtons/Button';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';

// styles
import classes from './Reservations.module.css';

const PAGE_LIMIT = 20;

var options = {year: 'numeric', month: 'numeric', day: 'numeric' , hour:'2-digit', minute:'2-digit'} ;
const parseDate = date => new Date(date).toLocaleString('es-ES', options);
const subscription = subs => (subs === 'TuPase') ? <div className={classes.Logo}> <img src={logoImage} alt="TuPase Admin" /></div> : null;
const snackbarIcon = {
  success: SuccessIcon,
  danger: DangerIcon
};

const Reservations = () => {
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'success'
  });
  
 const { getIdTokenClaims } = useAuth0();

  const dispatch = useDispatch();
  const { reservations, loading } = useSelector(state => state.reservations);
  const { centers } = useSelector(state => state.centers);
  const { date } = useSelector(state => state.date);
  const { status } = useSelector(state => state.status);
  const { entity } = useSelector(state => state.entity);
  useEffect(() => {
    if(centers.length === 0){
      fetchSportsCenters();
    }
   fetchReservations();
  }, []);

  const statuses =[ 
    { value: 'WAITING_QUOTA', label: 'En Espera' },
    { value: 'PENDING_CONFIRMATION', label: 'Pendiente Confirmación' },
    { value: 'CONFIRMED', label: 'Confirmada' },
    { value: 'CREATED_MANUALLY', label: 'Creada Manualmente' },
    { value: 'CANCELLED', label: 'Cancelada' }
  ]
  const fetchReservations = async (entityId, date, status )=> {
    const { __raw } = await getIdTokenClaims();
    dispatch(reservationsActions.fetchReservations(__raw, entityId, date, status));
  };

  const fetchSportsCenters = async () => {
    const { __raw } = await getIdTokenClaims();
    dispatch(reservationsActions.fetchSportsCenters(__raw));
  };

  const pageClickHandler = ({ currentPage }) => {
    setCurrentPage(currentPage);
  };

  function statusChange(event, value)  {
    if(value){
      fetchReservations(entity, date, value.value);
    }else{
      fetchReservations(entity, date);
    }
  } 

  function sportCenterChange (event, value)  {
    if(value){
      fetchReservations(value.entityId, date, status);
    }else{
      fetchReservations(undefined, date, status);
    }
  };

  const selectReservationHandler = id => {
    const reservation = reservations.find(reservation => reservation.id === id);
    setSelectedReservation(reservation);
    setIsDialogOpen(true);
  };

  const closeDialogHandler = () => {
    setSelectedReservation(null);
    setConfirmationCode('');
    setIsDialogOpen(false);
  };

  const confirmationCodeChangeHandler = event => {
    const { value } = event.target;
    setConfirmationCode(value.toUpperCase());
  };

  const confirmReservationHandler = async () => {
    const { id, documentoUsuario, fechaActividad } = selectedReservation;
    try {
      const { __raw } = await getIdTokenClaims();
      await dispatch(
        reservationsActions.confirmReservation(
          __raw,
          id,
          confirmationCode,
          documentoUsuario,
          fechaActividad
        )
      );
      setSnackbar({
        open: true,
        message: 'La reserva fue confirmada con éxito',
        type: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response.data.showMessage,
        type: 'danger'
      });
    }

    setSelectedReservation(null);
    setConfirmationCode('');
    setIsDialogOpen(false);
  };

  let reservationsList = null;
  if (loading) {
    reservationsList = <Spinner />;
  } else {
      if (reservations.length > 0) {
        const currentPageReservations = reservations.slice(
          (currentPage - 1) * PAGE_LIMIT,
          currentPage * PAGE_LIMIT
        );
        const reservationsData = currentPageReservations.map(reservation => {
          return [
            reservation.id,
            parseDate(reservation.fechaActividad),
            reservation.nombreUsuario,
            reservation.documentoUsuario,
            reservation.actividad,
            subscription(reservation.subscriptionPlatform),
            reservation.estado
          ];
        });

        reservationsList = (
          <div>
            <TableList
            title="Reservas"
            style={{ minWidth: '768px' }}
            head={[
              'Id',
              'Fecha de reserva',
              'Nombre completo',
              'Documento',
              'Actividad',
              "",
              'Estado',
              'Acciones'
            ]}
            data={reservationsData}
            confirmable
            onConfirm={selectReservationHandler}
            pagination={
              <Pagination
              totalRecords={reservations.length}
              pageLimit={PAGE_LIMIT}
              pageNeighbours={10}
              onPageChanged={pageClickHandler}
              />
            }
            />
          <Workbook filename="reservas.xlsx" element={<Button color='skyblue'> Exportar a Excel</Button>}>
            <Workbook.Sheet data={reservations} name="Reservas">
              <Workbook.Column label="Fecha de reserva" value={(col) => col.fechaActividad ? parseDate(col.fechaActividad) : null } />
              <Workbook.Column label="Nombre usuario" value="nombreUsuario" />
              <Workbook.Column label="Documento usuario" value="documentoUsuario" />
              <Workbook.Column label="Actividad" value="actividad" />
              <Workbook.Column label="Plataforma" value= {(col) => col.subscriptionPlatform === 'TuPase'? col.subscriptionPlatform : null }/>
              <Workbook.Column label="Estado" value="estado" />
            </Workbook.Sheet>
          </Workbook>
          </div>
        );
      }else{
        //no hay reservas para el centro logueado.
        reservationsList = (
        <Alert variant="outlined" severity="info">
          <AlertTitle>Atención!</AlertTitle>
          No existen reservas recientes para tu centro deportivo.
          <span>Cualquier consulta comunicate con <strong>soporte@tupaseapp.com</strong></span>
        </Alert>
        );
      }
        
    } 
    let autoCompleteList = null;
    
    if (centers.length > 1) {
      autoCompleteList = (
        <Autocomplete
        id="sucursales"
        options={centers}
        getOptionLabel={option => option.entityName}
        onChange = {sportCenterChange}
        classes = {{option: classes.AutoCompleteText}}
        renderInput={params => (
          <TextField {...params} label="Sucursal/Centro" variant="outlined" fullWidth />
        )}
      />
      );
    }
  let dialogMessage = '';
  if (selectedReservation) {
    dialogMessage = `Para confirmar la reserva del usuario ${selectedReservation.nombreUsuario} (${selectedReservation.documentoUsuario}), ingrese a continuación el código de confirmación de la reserva`;
  }
  return (
    <Fragment>
      <Grid container spacing={2}>
        {/* filtro por mes/año */}
        <Grid   item xs={12} sm={3} xl={2}><YearMonthPicker/></Grid>
        {/* filtro por centro */}
        <Grid   item xs={12} sm={3} xl={3}>{autoCompleteList}</Grid>
        <Grid   item xs={12} sm={3} xl={3}>
          <Autocomplete
            id="estados"
            options={statuses}
            getOptionLabel={option => option.label}
            onChange = {statusChange}
            classes = {{option: classes.AutoCompleteText}}
            renderInput={params => (
              <TextField {...params} label="Estado reserva" variant="outlined" fullWidth />
            )}
        />
        </Grid>
      </Grid>
      <br></br>
    {/* lista de reservas */}
      {reservationsList}
      <Dialog
        open={isDialogOpen}
        onClose={closeDialogHandler}
        aria-labelledby="confirmation-dialog"
      >
        <DialogTitle id="confirmation-dialog">Confirmar reserva</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Código de confirmación"
            type="text"
            fullWidth
            onChange={confirmationCodeChangeHandler}
            value={confirmationCode}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialogHandler} color="red">
            Cancelar
          </Button>
          <Button
            onClick={confirmReservationHandler}
            color="success"
            disabled={!confirmationCode}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        place="bl"
        color={snackbar.type}
        icon={snackbarIcon[snackbar.type]}
        message={snackbar.message}
        open={snackbar.open}
        closeNotification={() => setSnackbar({ ...snackbar, open: false })}
        close
      />
    </Fragment>
  );
};

export default Reservations;
