import * as actionTypes from './actionTypes';
import axios from '../../axios';

const fetchTimesStart = () => {
  return {
    type: actionTypes.FETCH_TIMES_START
  };
};

const fetchTimesSuccess = times => {
  return {
    type: actionTypes.FETCH_TIMES_SUCCESS,
    times: times
  };
};

const fetchTimesFail = error => {
  return {
    type: actionTypes.FETCH_TIMES_FAIL,
    error: error
  };
};

export const fetchTimes = (token, eId) => async dispatch => {
  dispatch(fetchTimesStart());
  try {
    const  {data}  =  await axios.get(
      `/search/activities/entityId/${eId}/timeTable`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ); 
    dispatch(fetchTimesSuccess(data));
  } catch (err) {
    dispatch(fetchTimesFail());
  }
};