import React, { Fragment, useEffect, forwardRef } from 'react';
import Button from '../../components/UI/CustomButtons/Button';
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '../../hooks/auth0';
import * as reservationsActions from '../../store/actions/reservations';


import Workbook from 'react-xlsx-workbook';
import { Alert, AlertTitle} from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
// styles
import tableStyle from '../../assets/jss/components/tableStyle';
import Layout from '../../hoc/Layout/Layout';
import Spinner from '../../components/UI/Spinner/Spinner';
import GridItem from '../../components/UI/Grid/GridItem';
import GridContainer from '../../components/UI/Grid/GridContainer';
import Card from '../../components/UI/Card/Card';
import CardHeader from '../../components/UI/Card/CardHeader';
import CardBody from '../../components/UI/Card/CardBody';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import {
  FirstPage, LastPage, Search, ChevronLeft, ChevronRight, Clear, ArrowUpward
} from '@material-ui/icons';

const tableIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward  {...props} ref={ref} />)

};
const UserReservations = ({ ...props }) => {

  var options = {year: 'numeric', month: 'numeric', day: 'numeric' , hour:'2-digit', minute:'2-digit'} ;
  const parseDate = date => new Date(date).toLocaleString('es-UY', options);

  const { getIdTokenClaims } = useAuth0();
  const dispatch = useDispatch();

  const  history = useHistory();
  const { userLogicalId, userName } = useParams();
  const { reservations, loadingUsers } = useSelector(state => state.reservations);

  function back() {
    history.goBack();
  }

  useEffect(() => {
    const fetchReservations = async () => {
      const { __raw } = await getIdTokenClaims();
      dispatch(reservationsActions.fetchReservationsUser(__raw, userLogicalId));
    };
    fetchReservations();
  }, []);

  let usersReservationList = null;
  if (loadingUsers) {
    usersReservationList = <Spinner />;
  } else {
    if (reservations && reservations.length > 0) {
      usersReservationList = (
        <div >
          <GridContainer >
            <GridItem xs={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={props.classes.cardTitleWhite}>Reservas del usuario: {userName}</h4>
                </CardHeader>
                <CardBody>
                  <div className={props.classes.tableResponsive}>
                  <MaterialTable
                      icons={tableIcons}
                      columns={[
                        { title: 'Fecha Actividad', field: 'fechaActividad', type: "datetime", dateSetting: {locale: "es-ES"}},
                        { title: 'Actividad', field: 'actividad' },
                        { title: 'Centro Deportivo', field: 'centro' },
                        { title: 'Estado', field: 'estado' }
                      ]}
                      data={reservations}
                      options={{
                        search: true,
                        showTitle: false,
                        showEmptyDataSourceMessage: false,
                        paginationType: 'stepped',
                        pageSizeOptions: [5, 10, 20],
                        pageSize: 10,
                        headerStyle: {
                          color: "grey",
                          fontSize: "1em",
                          fontWeight: "bold",
                          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                        },
                        rowStyle: {
                          color: "grey",
                          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                          lineHeight: "1.42857143",
                          padding: "12px 8px",
                          verticalAlign: "middle",
                        }
                      }}
           
                      localization={{
                        pagination: {
                          labelDisplayedRows: '{from}-{to} de {count}',
                          labelRowsSelect: 'Reservas por página',
                          firstAriaLabel: 'Primer página',
                          firstTooltip: 'Primer página',
                          previousAriaLabel: 'Anterior',
                          previousTooltip: 'Anterior',
                          nextAriaLabel: 'Siguiente',
                          nextTooltip: 'Siguiente',
                          lastAriaLabel: 'Última página',
                          lastTooltip: 'Última página'
                        },
                        toolbar: {
                          searchTooltip: 'Buscar',
                          searchPlaceholder: 'Buscar'
                        }
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <Workbook filename="reservasTuPase.xlsx" element={<Button color='skyblue'> Exportar a Excel</Button>}>
            <Workbook.Sheet data={reservations} name="Reservas">
              <Workbook.Column label="Fecha Actividad" value={(col) => col.fechaActividad ? parseDate(col.fechaActividad) : null}/>
              <Workbook.Column label="Actividad" value="actividad" />
              <Workbook.Column label="Centro deportivo" value="centro" />
              <Workbook.Column label="Estado" value="estado" />
            </Workbook.Sheet>
          </Workbook>
        </div>
      );
    } else {

      usersReservationList = (
        <Alert variant="outlined" severity="info">
          <AlertTitle>Atención!</AlertTitle>
          No existen reservas para el usuario {userName}.
          <span>Cualquier consulta comunicate con <strong>soporte@tupaseapp.com</strong></span>
        </Alert>
      );
    }
  }
  return (
    <Fragment>
        <Layout>
        {usersReservationList}  
        <div style={{ textAlign: 'right' }}>
          <Button color='skyblue' onClick={back}>Volver</Button>
          </div>
         </Layout>

    </Fragment>
  );
};

export default  withRouter(withStyles(tableStyle)(UserReservations));
