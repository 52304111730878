import * as actionTypes from '../actions/actionTypes';

const initialState = {
  roles: [],
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ROLES_START:
      return {
        ...state,
        error: null
      };
    case actionTypes.FETCH_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.roles
      };
    case actionTypes.FETCH_ROLES_FAIL:
      return {
        ...state,
        roles: [],
        error: action.error
      };
    default:
      return state;
  }
};
