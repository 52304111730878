import React from 'react';
// material ui core components
import { CircularProgress } from '@material-ui/core';
// styles
import classes from './Spinner.module.css';

const Spinner = () => (
  <CircularProgress className={classes.Spinner} color="primary" />
);

export default Spinner;
