import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from './hooks/auth0';
import history from './utils/history';
// components
import App from './containers/App';
// material ui core components
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// redux
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
// redux reducers
import reservationsReducer from './store/reducers/reservations';
import timesReducer from './store/reducers/times';
import statementsReducer from './store/reducers/statements';
import usersReducer from './store/reducers/users';
import rolesReducer from './store/reducers/roles';

// styles
import './index.css';
const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#dcdcdc'
    },
    secondary: {
      main: '#ffffff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  reservations: reservationsReducer,
  activities: reservationsReducer,
  activitiesUsers: reservationsReducer,
  waitingUsers: reservationsReducer,
  centers: reservationsReducer,
  date : reservationsReducer,
  entity : reservationsReducer,
  status : reservationsReducer,
  times : timesReducer,
  reservationsSatements: statementsReducer,
  totalsSatements: statementsReducer,
  dateStatements: statementsReducer,
  entityStatements: statementsReducer,
  users: usersReducer,
  companys: usersReducer,
  roles:rolesReducer
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const app = (
  <Provider store={store}>
    <MuiThemeProvider theme={muiTheme}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirect_uri={window.location.origin + process.env.REACT_APP_BASE_REF}
        onRedirectCallback={onRedirectCallback}
      >
        <BrowserRouter
          basename={process.env.REACT_APP_BASE_REF}
          history={history}
        >
          <App />
        </BrowserRouter>
      </Auth0Provider>
    </MuiThemeProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
