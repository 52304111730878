import React, { useState, Fragment, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '../../hooks/auth0';

import { withStyles } from '@material-ui/core/styles';
import Spinner from '../../components/UI/Spinner/Spinner';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import Snackbar from '../../components/UI/Snackbar/Snackbar';

import {
  CheckCircleRounded, ArrowUpward, Error as DangerIcon, AddCircleOutlineRounded,
  CloseRounded, CheckRounded
} from '@material-ui/icons';
// styles
import tableStyle from '../../assets/jss/components/tableStyle';

import * as reservationsActions from '../../store/actions/reservations';

const tableIcons = {
  ViewColumn: forwardRef((props, ref) => <CheckCircleRounded {...props} ref={ref} style={{ fill: "green" }} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward  {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddCircleOutlineRounded {...props} ref={ref} style={{ fill: "green", fontSize: 40 }} />),
  Check: forwardRef((props, ref) => <CheckRounded {...props} ref={ref} style={{ fill: "green" }} />),
  Clear: forwardRef((props, ref) => <CloseRounded {...props} ref={ref} style={{ fill: "red" }} />),
};

const snackbarIcon = {
  danger: DangerIcon
};

const AttendanceControlUsers = (props) => {
  const { getIdTokenClaims } = useAuth0();
  const dispatch = useDispatch();
  const { activitiesUsers, loadingUsers } = useSelector(state => state.activitiesUsers);
  const { waitingUsers} = useSelector(state => state.waitingUsers);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'success'
  });

  const createReservation = async (user) => {
    try {
      const { __raw } = await getIdTokenClaims();
      await dispatch(
        reservationsActions.createReservationUser(
          __raw,
          user.documentoUsuario,
          props.rowData.idActividad,
        )
      );
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response.data.showMessage,
        type: 'danger'
      });
    };
  }

  const confirmReservation = async (reservation) => {
    const { id, codActivacion, documentoUsuario, fechaActividad } = reservation;
    try {
      const { __raw } = await getIdTokenClaims();
      await dispatch(
        reservationsActions.confirmReservationUser(
          __raw,
          id,
          codActivacion,
          documentoUsuario,
          fechaActividad
        )
      );
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'No se puede confirmar la asistencia',
        type: 'danger'
      });
    };
  };

  const confirmWaitingReservation = async (reservation) => {
    const { id, codActivacion, documentoUsuario, fechaActividad } = reservation;
    try {
      const { __raw } = await getIdTokenClaims();
      await dispatch(
        reservationsActions.confirmReservationUser(
          __raw,
          id,
          codActivacion,
          documentoUsuario,
          fechaActividad
        )
      );
      dispatch(reservationsActions.fetchActivitiesUsers(__raw, null, null, props.rowData.idActividad));
      dispatch(reservationsActions.fetchWaitingUsers(__raw, null, props.rowData.idActividad));
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'No se puede confirmar la asistencia',
        type: 'danger'
      });
    };
  };

  let usersList = null;
  if (loadingUsers) {
    usersList = <Spinner />;
  } else {
      usersList = (
        <div className={props.classes.tableResponsive} >
          <MaterialTable
            icons={tableIcons}
            title="Usuarios Confirmados"
            columns={[
              { title: 'Nombre Completo', field: 'nombreUsuario',  editable: 'never' },
              { title: 'Documento', field: 'documentoUsuario' },
            ]}
            data={activitiesUsers}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  createReservation(newData);
                  resolve();
                }),
            }}
            options={{
              actionsColumnIndex: 2,
              toolbar: true,
              search: false,
              paging: false,
              headerStyle: {
                color: "grey",
                fontSize: "1em",
                fontWeight: "bold",
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
              },
              rowStyle: rowData => ({
                color: "grey",
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                lineHeight: "1.42857143",
                verticalAlign: "middle",
                fontStyle: ('Creada Manualmente' === rowData.estado) ? "oblique": "normal",
                backgroundColor: ('Confirmada' === rowData.estado) ? '#EFFCFF' : ( ('Creada Manualmente' === rowData.estado) ? '#eff4ff':'#FFF') 
              })
            }}
            actions={[
              rowData => ({
                icon: tableIcons.ViewColumn,
                tooltip: 'Confirmar Asistencia',
                onClick: (event, rowData) => confirmReservation(rowData),
                hidden: rowData.estado === 'Confirmada' || rowData.estado === 'Creada Manualmente'
              }),
            ]}
            localization={{
              body: {
                addTooltip: 'Ingresar usuario',
                emptyDataSourceMessage:'No existen usuarios registrados en la actividad seleccionada',
                editRow: {
                  saveTooltip: 'Guardar',
                  cancelTooltip: 'Cancelar'
                }
              },
            }}

          />
          <br /><br />
          <MaterialTable
            icons={tableIcons}
            title="Usuarios en Espera"
            columns={[
              { title: 'Nombre Completo', field: 'nombreUsuario' },
              { title: 'Documento', field: 'documentoUsuario' },
            ]}
            data={waitingUsers}
            options={{
              actionsColumnIndex: 2,
              toolbar: true,
              search: false,
              paging: false,
              headerStyle: {
                color: "grey",
                fontSize: "1em",
                fontWeight: "bold",
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
              },
              rowStyle: ({
                color: "grey",
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                lineHeight: "1.42857143",
                verticalAlign: "middle",
              })
            }}
            actions={[
              rowData => ({
                icon: tableIcons.ViewColumn,
                tooltip: 'Confirmar Asistencia',
                onClick: (event, rowData) => confirmWaitingReservation(rowData),
                hidden: rowData.estado === 'Confirmada'
              }),
            ]}
            localization={{
              body: {
                emptyDataSourceMessage:'No existen usuarios en espera  para la  actividad seleccionada',
              },
            }}
          />
        </div>

      );
  }
  return (
    <Fragment>
      {usersList}
      <Snackbar
        place="bl"
        color={snackbar.type}
        icon={snackbarIcon[snackbar.type]}
        message={snackbar.message}
        open={snackbar.open}
        closeNotification={() => setSnackbar({ ...snackbar, open: false })}
        close
      />
    </Fragment>

  );
};
AttendanceControlUsers.propTypes = {
  rowData: PropTypes.object
};
export default withStyles(tableStyle)(AttendanceControlUsers);
