import React, { Fragment, useEffect, forwardRef } from 'react';
import MaterialTable from 'material-table';
import Workbook from 'react-xlsx-workbook';
import Button from '../../components/UI/CustomButtons/Button';

// material ui components
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

import {
  TextField,
  Grid
} from '@material-ui/core';

import {
  FirstPage, LastPage, Search, ChevronLeft, ChevronRight, Clear, ArrowUpward, CheckOutlined,
  BlockOutlined, EventNoteRounded,SearchRounded 
} from '@material-ui/icons';

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '../../hooks/auth0';
// styles
import classes from '../Reservations/Reservations.module.css';
// styles
import tableStyle from '../../assets/jss/components/tableStyle';

import * as usersActions from '../../store/actions/users';
import Spinner from '../../components/UI/Spinner/Spinner';
import GridItem from '../../components/UI/Grid/GridItem';
import GridContainer from '../../components/UI/Grid/GridContainer';
import Card from '../../components/UI/Card/Card';
import CardHeader from '../../components/UI/Card/CardHeader';
import CardBody from '../../components/UI/Card/CardBody';
import { withRouter } from 'react-router-dom';

const tableIcons = {
  Add: forwardRef((props, ref) => <CheckOutlined {...props} ref={ref} style={{ fill: "green" }} />),
  Delete: forwardRef((props, ref) => <BlockOutlined {...props} ref={ref} style={{ fill: "red" }} />),
  View: forwardRef((props, ref) => <EventNoteRounded {...props} ref={ref} style={{ fill: "grey" }} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward  {...props} ref={ref} />)

};

const UsersAdmin = (props) => {
  const { getIdTokenClaims } = useAuth0();
  const dispatch = useDispatch();
  const { users, loading } = useSelector(state => state.users);
  const { companys } = useSelector(state => state.companys);
  const history = useHistory();
  const [nombre, setNombre] = React.useState('');
  const [apellido, setApellido] = React.useState('');
  const [documento, setDocumento] = React.useState('');


  useEffect(() => {
    if (companys.length === 0) {
      fetchCompanys();
    }
  }, []);

  const fetchCompanys = async () => {
    const { __raw } = await getIdTokenClaims();
    dispatch(usersActions.fetchCompanys(__raw));
  };

  const handleChangeNombre = (event) => {
      setNombre(event.target.value);
  };

  const handleChangeApellido = (event) => {
      setApellido(event.target.value);
  };

  const handleChangeDocumento = (event) => {
    setDocumento(event.target.value);
  };

  const searchUser  = async () =>{
    const { __raw } = await getIdTokenClaims();
    dispatch(usersActions.searchUser(__raw, nombre, apellido, documento));
   }
   
  function companysChange(event, value) {
    if (value) {
      fetchUsers(value.companyId);
    }
  }


  const fetchUsers = async (companyId) => {
    const { __raw } = await getIdTokenClaims();
    dispatch(usersActions.fetchUsers(__raw, companyId));
  };

  const activeUser = async (userLogicalId) => {
    const { __raw } = await getIdTokenClaims();
    dispatch(usersActions.activeUser(__raw, userLogicalId));
  };

  const inactiveUser = async (userLogicalId) => {
    const { __raw } = await getIdTokenClaims();
    dispatch(usersActions.inactiveUser(__raw, userLogicalId));
  };

  let usersList = null;
  if (loading) {
    usersList = <Spinner />;
  } else {
    if (users && users.length > 0) {
      usersList = (
        <div >
          <GridContainer >
            <GridItem xs={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={props.classes.cardTitleWhite}>Usuarios</h4>
                </CardHeader>
                <CardBody>
                  <div className={props.classes.tableResponsive}>
                    <MaterialTable
                      icons={tableIcons}
                      columns={[
                        { title: 'Nombre Completo', field: 'userFullName' },
                        { title: 'Documento', field: 'userDocument' },
                        { title: 'Teléfono', field: 'phone' },
                        { title: 'Correo', field: 'email' },
                        { title: 'Empresa', field: 'companyName' },
                        { title: 'Estado', field: 'statusStr' }
                      ]}
                      data={users}
                      options={{
                        search: true,
                        showTitle: false,
                        showEmptyDataSourceMessage: false,
                        paginationType: 'stepped',
                        pageSizeOptions: [5, 10, 20],
                        pageSize: 10,
                        actionsColumnIndex: 6,
                        headerStyle: {
                          color: "grey",
                          fontSize: "1em",
                          fontWeight: "bold",
                          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                        },
                        rowStyle: {
                          color: "grey",
                          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                          lineHeight: "1.42857143",
                          padding: "12px 8px",
                          verticalAlign: "middle",
                        }
                      }}
                      actions={[
                        rowData => ({
                          icon: tableIcons.Add,
                          tooltip: 'Activar Usuario',
                          onClick: (event, rowData) => activeUser(rowData.userLogicalId),
                          hidden: (rowData.status === 'ACTIVE' || rowData.status === 'ACTIVATION_PENDING')
                        }),
                        rowData => ({
                          icon: tableIcons.Delete,
                          tooltip: 'Desactivar Usuario',
                          onClick: (event, rowData) => inactiveUser(rowData.userLogicalId),
                          hidden: rowData.status === 'INACTIVE'
                        }),
                        {
                          icon: tableIcons.View,
                          tooltip: 'Reservas Usuario',
                          onClick: (event, rowData) => history.push(`/userReservation/${rowData.userLogicalId}/${rowData.userFullName}`)
                        }
                      ]}
                      localization={{
                        header: {
                          actions: 'Acciones'
                        },
                        pagination: {
                          labelDisplayedRows: '{from}-{to} de {count}',
                          labelRowsSelect: 'Usuarios por página',
                          firstAriaLabel: 'Primer página',
                          firstTooltip: 'Primer página',
                          previousAriaLabel: 'Anterior',
                          previousTooltip: 'Anterior',
                          nextAriaLabel: 'Siguiente',
                          nextTooltip: 'Siguiente',
                          lastAriaLabel: 'Última página',
                          lastTooltip: 'Última página'
                        },
                        toolbar: {
                          searchTooltip: 'Buscar',
                          searchPlaceholder: 'Buscar'
                        }
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <Workbook filename="usuariosTuPase.xlsx" element={<Button color='skyblue'> Exportar a Excel</Button>}>
            <Workbook.Sheet data={users} name="Usuarios">
              <Workbook.Column label="Nombre usuario" value="userFullName" />
              <Workbook.Column label="Documento usuario" value="userDocument" />
              <Workbook.Column label="Teléfono" value="phone" />
              <Workbook.Column label="Correo" value="email" />
              <Workbook.Column label="Estado" value="status" />
            </Workbook.Sheet>
          </Workbook>
        </div>
      );
    } else {

      usersList = (
        <Alert variant="outlined" severity="info">
          <AlertTitle>Atención!</AlertTitle>
          No existen usuarios registrados para su empresa.
          <span>Cualquier consulta comunicate con <strong>soporte@tupaseapp.com</strong></span>
        </Alert>
      );
    }
  }
  let autoCompleteList = null;
  if (companys.length > 1) {
    autoCompleteList = (
      <Autocomplete
        id="empresas"
        options={companys}
        getOptionLabel={option => option.companyName}
        onChange={companysChange}
        classes={{ option: classes.AutoCompleteText }}
        renderInput={params => (
          <TextField {...params} label="Empresa" variant="outlined" fullWidth />
        )}
      />
    );
  }
  return (
    <Fragment>
      <Grid container spacing={2}>
        {/* filtro por empresa */}
        <Grid item xs={12} sm={3} xl={3}>
          {autoCompleteList}
        </Grid>
      </Grid>
      
      <br></br><br></br>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} xl={2}>
        <TextField id="nom" label="Nombre" variant="outlined" value={nombre} onChange={handleChangeNombre} />
        </Grid>
        <Grid item xs={12} sm={3} xl={2}>
        <TextField id="ap" label="Apellido" variant="outlined" value={apellido} onChange={handleChangeApellido}/>
        </Grid>
        <Grid item xs={12} sm={3} xl={2}>
        <TextField id="ci" label="Documento" variant="outlined" value={documento} onChange={handleChangeDocumento}/>
        </Grid>
        <Grid item xs={12} sm={3} xl={6}>
        <Button color='skyblue' startIcon={<SearchRounded />} onClick={searchUser}> Buscar</Button>
        </Grid>
      </Grid>
      <br></br>
      {usersList}
    </Fragment>

  );
};

export default withRouter(withStyles(tableStyle)(UsersAdmin));