import * as actionTypes from '../actions/actionTypes';

const initialState = {
  times: [],
  loadingTimes: false,
  errorTimes: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TIMES_START:
      return {
        ...state,
        loadingTimes: true,
        errorTimes: null
      };
    case actionTypes.FETCH_TIMES_SUCCESS:
      return {
        ...state,
        loadingTimes: false,
        times: action.times
      };
    case actionTypes.FETCH_TIMES_FAIL:
      return {
        ...state,
        loadingTimes: false,
        errorTimes: action.error,
        times:[]
      };
    default:
      return state;
  }
};
