import React, { Component } from 'react';
import { Route } from 'react-router-dom';
// components
import Login from '../../../containers/Auth/Login/Login';
// styles
import classes from './FullScreenLayout.module.css';

class FullScreenLayout extends Component {
  render() {
    return (
      <div className={classes.Content}>
        <Route path="/" component={Login} />
      </div>
    );
  }
}

export default FullScreenLayout;
