import React from 'react';
import { useAuth0 } from '../../../hooks/auth0';
import { Button, Card } from '@material-ui/core';
// styles
import classes from './Login.module.css';

import logoImage from '../../../assets/images/tupase-logo.png';

const Login = props => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (isAuthenticated) {
    props.history.push('/');
  }

  return (
    <div className={classes.Auth}>
      <div className={classes.AuthFormContainer}>
        <div className={classes.AuthFormSubContainer}>
          <img
            className={classes.Logo}
            src={logoImage}
            alt="TuPase"
          />
          <Card className={classes.AuthForm}>
            <Button
              className={classes.SubmitButton}
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => loginWithRedirect({})}
            >
              Iniciar sesión
            </Button>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Login;
