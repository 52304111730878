import React from 'react';
import { NavLink } from 'react-router-dom';
// assets
import logoImage from '../../assets/images/tupase-logo.png';
// styles
import classes from './Logo.module.css';

const logo = () => (
  <div className={classes.Logo}>
    <NavLink to="/">
      <img src={logoImage} alt="TuPase Admin" />
    </NavLink>
  </div>
);

export default logo;
