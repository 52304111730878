import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// components
import Header from '../../components/Header/Header';
import SideDrawer from '../../components/UI/SideDrawer/SideDrawer';
// material ui core components
import withStyles from '@material-ui/core/styles/withStyles';
// routes
import routes from '../../routes/index';
// styles
import layoutStyle from '../../assets/jss/layout.jsx';

class Layout extends Component {
  state = {
    showSideDrawer: false
  };

  sideDrawerClosedHandler = () => {
    this.setState({ showSideDrawer: false });
  };

  sideDrawerToggleHandler = () => {
    this.setState(prevState => {
      return { showSideDrawer: !prevState.showSideDrawer };
    });
  };

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className={classes.wrapper}>
        <SideDrawer
          open={this.state.showSideDrawer}
          closed={this.sideDrawerClosedHandler}
          onItemSelected={this.sideDrawerClosedHandler}
          handleDrawerOpen={this.sideDrawerToggleHandler}
          routes={routes}
          onLogout={rest.onLogout}
        />

        <div className={classes.mainPanel} ref="mainPanel">
          <Header
            drawerToggleClicked={this.sideDrawerToggleHandler}
            routes={routes}
            onLogout={rest.onLogout}
            {...rest}
          />
          <div className={classes.content}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(layoutStyle)(Layout));
