export const FETCH_RESERVATIONS_START = 'FETCH_RESERVATIONS_START';
export const FETCH_RESERVATIONS_SUCCESS = 'FETCH_RESERVATIONS_SUCCESS';
export const FETCH_RESERVATIONS_FAIL = 'FETCH_RESERVATIONS_FAIL';
export const CONFIRM_RESERVATION_SUCCESS = 'CONFIRM_RESERVATION_SUCCESS';

export const CONFIRM_QUOTA_SUCCESS = 'CONFIRM_QUOTA_SUCCESS';
export const CANCEL_RESERVATION_SUCCESS = 'CANCEL_RESERVATION_SUCCESS';

export const FETCH_CENTERS_START = 'FETCH_CENTERS_START';
export const FETCH_CENTERS_SUCCESS = 'FETCH_CENTERS_SUCCESS';
export const FETCH_CENTERS_FAIL = 'FETCH_CENTERS_FAIL';

export const FETCH_TIMES_START = 'FETCH_TIMES_START';
export const FETCH_TIMES_SUCCESS = 'FETCH_TIMES_SUCCESS';
export const FETCH_TIMES_FAIL = 'FETCH_TIMES_FAIL';

export const FETCH_STATEMENTS_START = 'FETCH_STATEMENTS_START';
export const FETCH_STATEMENTS_SUCCESS = 'FETCH_STATEMENTS_SUCCESS';
export const FETCH_STATEMENTS_FAIL = 'FETCH_STATEMENTS_FAIL';


export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const ACTIVATE_USERS_FAIL = 'ACTIVATE_USERS_FAIL';
export const ACTIVATE_USERS_SUCCESS = 'ACTIVATE_USERS_SUCCESS';
export const INACTIVATE_USERS_FAIL = 'INACTIVATE_USERS_FAIL';
export const INACTIVATE_USERS_SUCCESS = 'INACTIVATE_USERS_SUCCESS';


export const FETCH_COMPANY_START = 'FETCH_COMPANY_START';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAIL = 'FETCH_COMPANY_FAIL';

export const FETCH_ACTIVITIES_START = 'FETCH_ACTIVITIES_START';
export const FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS';
export const FETCH_ACTIVITIES_FAIL = 'FETCH_ACTIVITIES_FAIL';

export const FETCH_ACTIVITIES_USERS_START = 'FETCH_ACTIVITIES_USERS_START';
export const FETCH_ACTIVITIES_USERS_SUCCESS = 'FETCH_ACTIVITIES_USERS_SUCCESS';
export const FETCH_ACTIVITIES_USERS_FAIL = 'FETCH_ACTIVITIES_USERS_FAIL';
export const CONFIRM_RESERVATION_USER_SUCCESS = 'CONFIRM_RESERVATION_USER_SUCCESS';
export const FETCH_WAITING_USERS_SUCCESS = 'FETCH_WAITING_USERS_SUCCESS';

export const FETCH_ROLES_START = 'FETCH_ROLES_START';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAIL = 'FETCH_ROLES_FAIL';