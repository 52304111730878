import React, { Fragment, useEffect, useState } from 'react';
import Workbook from 'react-xlsx-workbook';

// material ui components
import {
  Grid, TextField, Table, TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '../../hooks/auth0';

// styles
import classes from '../Reservations/Reservations.module.css';
import tableStyle from '../../assets/jss/components/tableStyle';

import * as reservationsActions from '../../store/actions/reservations';
import * as statementsActions from '../../store/actions/statements';
import Pagination from '../../components/UI/Pagination/Pagination';
import YearMonthPicker from '../../components/UI/Date/YearMonthPicker';
import Spinner from '../../components/UI/Spinner/Spinner';
import GridItem from '../../components/UI/Grid/GridItem';
import GridContainer from '../../components/UI/Grid/GridContainer';
import Card from '../../components/UI/Card/Card';
import CardHeader from '../../components/UI/Card/CardHeader';
import CardBody from '../../components/UI/Card/CardBody';
import Button from '../../components/UI/CustomButtons/Button';

const PAGE_LIMIT = 10;
const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Setiembre", "Octubre", "Noviembre", "Diciembre"];

const Statements = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { getIdTokenClaims } = useAuth0();
  const dispatch = useDispatch();
  const { centers } = useSelector(state => state.centers);
  const { reservationsSatements, loadingStatements } = useSelector(state => state.reservationsSatements);
  const { totalsSatements } = useSelector(state => state.totalsSatements);
  const { dateStatements } = useSelector(state => state.dateStatements);


  useEffect(() => {
    if (centers.length === 0) {
      fetchSportsCenters();
    }else{
      if (centers.length === 1) {
        fetchStatements(centers[0].entityId);
      }
    }
  }, []);

  const pageClickHandler = ({ currentPage }) => {
    setCurrentPage(currentPage);
  };

  const fetchSportsCenters = async () => {
    const { __raw } = await getIdTokenClaims();
    dispatch(reservationsActions.fetchSportsCenters(__raw));
  };
  const fetchStatements = async (entityId, date) => {
    const { __raw } = await getIdTokenClaims();
    dispatch(statementsActions.fetchStatements(__raw, entityId, date));
  };

  function sportCenterChange(event, value) {
    let eID = null;
    if (value) {
      eID = value.entityId;
    }
    fetchStatements(eID, dateStatements);
  };

  var options = {year: 'numeric', month: 'numeric', day: 'numeric' , hour:'2-digit', minute:'2-digit'} ;
  const parseDate = date => new Date(date).toLocaleString('es-ES', options);

  function parseDateToString(date) {
    if (date) {
      let formatted_date = months[date.getMonth()] + "-" + date.getFullYear();
      return formatted_date;
    }
    return "";
  }

  const name = "estadocuentaTuPase" + parseDateToString(dateStatements) + ".xlsx";

  let statementsList = null;
  if (loadingStatements) {
    statementsList = <Spinner />;
  } else {
    if (reservationsSatements && reservationsSatements.length > 0) {
      const currentPageReservations = reservationsSatements.slice(
        (currentPage - 1) * PAGE_LIMIT,
        currentPage * PAGE_LIMIT
      );
      statementsList = (
        <div >
          <GridContainer >
            <GridItem xs={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={props.classes.cardTitleWhite}>Estado de cuenta {parseDateToString(dateStatements)}</h4>
                </CardHeader>
                <CardBody>
                  <div className={props.classes.tableResponsive}>
                    <Table className={props.classes.table} >
                      <TableHead className={props.classes.primaryTableHeader}>
                        <TableRow>
                          <TableCell className={props.classes.tableCell + ' ' + props.classes.tableHeadCell}>Fecha de reserva</TableCell>
                          <TableCell className={props.classes.tableCell + ' ' + props.classes.tableHeadCell}>Nombre usuario</TableCell>
                          <TableCell className={props.classes.tableCell + ' ' + props.classes.tableHeadCell}>Actividad</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentPageReservations.map(row => (
                          <TableRow key={row.id}>
                            <TableCell className={row.flatFee ? props.classes.tableCell1 : props.classes.tableCell}>{parseDate(row.activityDate)}</TableCell>
                            <TableCell className={row.flatFee ? props.classes.tableCell1 : props.classes.tableCell}>{row.userName}</TableCell>
                            <TableCell className={row.flatFee ? props.classes.tableCell1 : props.classes.tableCell}>{row.activityName}</TableCell>
                          </TableRow>

                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      totalRecords={reservationsSatements.length}
                      pageLimit={PAGE_LIMIT}
                      pageNeighbours={10}
                      onPageChanged={pageClickHandler}
                    />
                    <br></br>

                    <Table>
                    {totalsSatements.map(totals => (
                      <TableBody>
                        <TableRow>
                          <TableCell rowSpan={4} />
                          <TableCell className={props.classes.tableCell + ' ' + props.classes.tableHeadCell}>Cantidad de Reservas Contabilizadas</TableCell>
                          <TableCell className={props.classes.tableCell}>{totals.reservationsCount}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={props.classes.tableCell + ' ' + props.classes.tableHeadCell}>Cantidad de Reservas a Pagar</TableCell>
                          <TableCell className={props.classes.tableCell}>{totals.paidReservationsCount}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={props.classes.tableCell + ' ' + props.classes.tableHeadCell}>Monto Acordado por Reserva</TableCell>
                          <TableCell className={props.classes.tableCell}>$ {totals.agreedFee}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={props.classes.successTableCell + ' ' + props.classes.tableHeadCell}>Monto Total</TableCell>
                          <TableCell className={props.classes.successTableCell} >$ {totals.paidAmount}</TableCell>
                        </TableRow>
                        </TableBody>
                        ))}
                      
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem>
              <Workbook filename={name} element={<Button color='skyblue'> Exportar a Excel</Button>}>
                <Workbook.Sheet data={reservationsSatements} name="Reservas">
                  <Workbook.Column label="Fecha de reserva" value="activityDate" />
                  <Workbook.Column label="Nombre usuario" value="userName" />
                  <Workbook.Column label="Actividad" value="activityName" />
                  <Workbook.Column label="Pago plano" value={(col) => col.flatFee ? "True" : "False"} />
                </Workbook.Sheet>
                <Workbook.Sheet data={totalsSatements} name="Pagos">
                  <Workbook.Column label="Cantidad de Reservas Contabilizadas" value="reservationsCount" />
                  <Workbook.Column label="Cantidad de Reservas a Pagar" value="paidReservationsCount"></Workbook.Column>
                  <Workbook.Column label="Monto Acordado por Reserva" value="agreedFee"></Workbook.Column>
                  <Workbook.Column label="Monto Total" value="paidAmount"></Workbook.Column>
                </Workbook.Sheet>
              </Workbook>

            </GridItem>
          </GridContainer>
        </div>
      );
    } else {
      //no hay estados de cuenta.
      statementsList = (
        <Alert variant="outlined" severity="info">
          <AlertTitle>Atención!</AlertTitle>
          No existen estados de cuenta registrados para el mes seleccionado.
          <span>Cualquier consulta comunicate con <strong>soporte@tupaseapp.com</strong></span>
        </Alert>
      );
    }
  }
  let autoCompleteList = null;

  if (centers.length > 1) {
    autoCompleteList = (
      <Autocomplete
        id="combo-box"
        options={centers}
        getOptionLabel={option => option.entityName}
        classes={{ option: classes.AutoCompleteText }}
        onChange={sportCenterChange}
        style={{ width: 300 }}
        renderInput={params => (
          <TextField {...params} label="Sucursal/Centro" variant="outlined" fullWidth />
        )}
      />
    );
  }
  return (
    <Fragment>
      <Grid container spacing={2}>
        {/* filtro por mes/año */}
        <Grid item xs={12} sm={3}><YearMonthPicker isStatements /></Grid>
        {/* filtro por centro */}
        <Grid item xs={12} sm={3}>{autoCompleteList}</Grid>
      </Grid>
      <br />
      {statementsList}
    </Fragment>

  );
};

export default withStyles(tableStyle)(Statements);