import * as actionTypes from '../actions/actionTypes';

const initialState = {
  centers: [],
  reservations: [],
  activities: [],
  activitiesUsers: [],
  waitingUsers: [],
  date:null,
  entity:undefined,
  status:null,
  loading: false,
  loadingUsers:false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_RESERVATIONS_START:
      return {
        ...state,
        loading: true,
        error: null,
        date: action.date,
        entity:action.entity,
        activitiesUsers: [],
        waitingUsers: [],
        status: action.status
      };
    case actionTypes.FETCH_RESERVATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        reservations: action.reservations
      };
    case actionTypes.FETCH_RESERVATIONS_FAIL:
      return {
        ...state,
        loading: false,
        reservations: [],
        error: action.error
      };
    case actionTypes.CONFIRM_RESERVATION_SUCCESS:
      return {
        ...state,
        reservations: state.reservations.map(reservation => {
          if (reservation.id === action.reservationId) {
            return {
              ...reservation,
              estado: 'Confirmada'
            };
          }return reservation;

        })
     };
      case actionTypes.CANCEL_RESERVATION_SUCCESS:
        return {
          ...state,
          reservations: state.reservations.map(reservation => {
            if (reservation.id === action.reservationId) {
              return {
                ...reservation,
                estado: 'Cancelada'
              };
            }
            return reservation;
  
          })
       };
       case actionTypes.CONFIRM_QUOTA_SUCCESS:
        return {
          ...state,
          reservations: state.reservations.map(reservation => {
            if (reservation.id === action.reservationId) {
              return {
                ...reservation,
                estado: 'Pendiente Confirmación'
              };
            }
            return reservation;
  
          })
       };
  
      case actionTypes.FETCH_CENTERS_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.FETCH_CENTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        centers: action.centers
      };
      case actionTypes.FETCH_CENTERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
      case actionTypes.FETCH_ACTIVITIES_START:
        return {
          ...state,
          loading: true,
          error: null,
          entity: action.entity,
        };
      case actionTypes.FETCH_ACTIVITIES_SUCCESS:
        return {
          ...state,
          loading: false,
          activities: action.activities
        };
      case actionTypes.FETCH_ACTIVITIES_FAIL:
        return {
          ...state,
          loading: false,
          activities: [],
          error: action.error
        };      
        case actionTypes.FETCH_ACTIVITIES_USERS_START:
          return {
            ...state,
            loadingUsers: true,
            error: null,
            entity: action.entity,
          };
        case actionTypes.FETCH_ACTIVITIES_USERS_SUCCESS:
          return {
            ...state,
            loadingUsers: false,
            activitiesUsers: action.activitiesUsers
          };
          case actionTypes.FETCH_WAITING_USERS_SUCCESS:
            return {
              ...state,
              loadingUsers: false,
              waitingUsers: action.waitingUsers
            };
          case actionTypes.FETCH_ACTIVITIES_USERS_FAIL:
          return {
            ...state,
            loadingUsers: false,
            activitiesUsers: [],
            error: action.error
          };
          case actionTypes.CONFIRM_RESERVATION_USER_SUCCESS:
            return {
              ...state,
              activitiesUsers: state.activitiesUsers.map(reservation => {
                if (reservation.id === action.reservationId) {
                  return {
                    ...reservation,
                    estado: 'Confirmada'
                  };
                }return reservation;
      
              })
           };                        
    default:
      return state;
  }
};
