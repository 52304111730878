// material ui icons
import { EventNoteRounded, CheckCircleOutlineRounded,DateRangeRounded,AttachMoneyRounded,SupervisorAccountRounded, PeopleOutlineRounded, CropFreeRounded } from '@material-ui/icons';
// containers
import Reservations from '../containers/Reservations/Reservations';
import AttendanceControl from '../containers/Reservations/AttendanceControl';
import ReservationsAdmin from '../containers/Reservations/ReservationsAdmin';
import Times from '../containers/Times/Times';
import Statements from '../containers/Statements/Statements';
import UsersAdmin from '../containers/Users/UsersAdmin';
import QrConfirmation from '../containers/Reservations/QrConfirmation';

const routes = [
  {
    path: '/qr',
    navbarName: 'Confirmación',
    sidebarName: 'Confirmación QR',
    role:'USER',
    icon: CropFreeRounded,
    component: QrConfirmation
  },
  {
    path: '/controlAsistencia',
    navbarName: 'Control Asistencia',
    sidebarName: 'Control Asistencia',
    role:'TRAINER',
    icon: PeopleOutlineRounded,
    component: AttendanceControl
 },
 {
    path: '/reservas',
    navbarName: 'Reservas',
    sidebarName: 'Reservas',
    role:'USER',
    icon: EventNoteRounded,
    component: Reservations
 },
 {
  path: '/horarios',
  navbarName: 'Horarios',
  sidebarName: 'Horarios',
  role:'USER',
  icon: DateRangeRounded,
  component: Times
},
{
  path: '/estadosCuentas',
  navbarName: 'Estados de Cuenta',
  sidebarName: 'Estados de Cuenta',
  role:'USER',
  icon: AttachMoneyRounded,
  component: Statements
},
{
  path: '/gestionUsuarios',
  navbarName: 'Gestión Usuarios',
  sidebarName: 'Gestión Usuarios',
  role:'COMPANY_USER',
  icon: SupervisorAccountRounded,
  component: UsersAdmin
},
 {
  path: '/gestionReservas',
  navbarName: 'Gestión Reservas',
  sidebarName: 'Gestión Reservas',
  icon: CheckCircleOutlineRounded,
  onlyAdmin:true,
  role:'ADMIN',
  component: ReservationsAdmin
}
];

export default routes;
